import axios from "axios";

var eazydb = {

  database: null,
  request: null,

  create(url) {
    this.request = axios.create({
      baseURL: url,
      timeout: 10000,
      headers: { 'Content-Type': 'application/json;charset=UTF-8' }
    })
    return this;
  },

  connect(db, token, callback) {
    if (this.request == null) {
      return;
    }
    this.request({
      method: "get",
      url: "/eazydb/connect",
      params: { db }
    })
      .then((res) => {
        this.database = db;
        callback(res.data.error_code == 0 ? null : res.data.reason)
      })
      .catch(() => {
        callback("请求失败")
      })
    this.request.interceptors.request.use(config => {
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8',
        'Authorization': token
      }
      return config
    }, err => { })
    return this;
  },

  insertOne(coll, table, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "insertone" },
      data: { table }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  insertMany(coll, tables, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "insertmany" },
      data: { tables }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  deleteOne(coll, filter, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "deleteone" },
      data: { filter }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  deleteMany(coll, filter, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "deletemany" },
      data: { filter }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  updateOne(coll, filter, update, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "updateone" },
      data: { filter, update }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  updateMany(coll, filter, update, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "updatemany" },
      data: { filter, update }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  findOne(coll, filter, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "findone" },
      data: { filter }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch((err) => {
        callback("请求失败")
      })
  },

  findMany(coll, filter, pageOption, callback) {
    var params = {
      db: this.database,
      coll,
      func: "findmany"
    };
    "index" in pageOption ? params["index"] = pageOption.index : null;
    "size" in pageOption ? params["size"] = pageOption.size : null;
    "sort" in pageOption ? params["sort"] = pageOption.sort : null;
    "order" in pageOption && "sort" in pageOption ? params["order"] = pageOption.order : null;
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params,
      data: { filter }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },

  countDocuments(coll, filter, callback) {
    this.request({
      method: "post",
      url: "/eazydb/mongo",
      params: { db: this.database, coll, func: "countdocuments" },
      data: { filter }
    })
      .then((res) => {
        res.data.error_code == 0 ?
          callback(null, res.data.result) :
          callback(res.data.reason);
      })
      .catch(() => {
        callback("请求失败")
      })
  },
}

export default eazydb;