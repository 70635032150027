<template>
  <!-- <div id="app">
    <div class="head"></div>
    <div class="left" />
    <div class="ctx">
      <div class="ctx-top"></div>
      <div class="ctx-view">
        <MANUAL :url="url" :token="token" :gatewayid="gatewayid" :writeEnable="true" />
      </div>
    </div>
  </div> -->
  <div id="app">
    <MANUALP :writeEnable="true" />
  </div>
</template>

<script>
import MANUAL from "./components/MANUAL/MANUAL";
import MANUALP from "./components/MANUAL/MANUAL_phone";

import axios from "axios";

export default {
  components: {
    MANUAL,
    MANUALP,
  },
  data() {
    return {
      url: "http://tolink.cd-kght.cn",
      token:
        "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJVc2VySUQiOiI2MGM5YWM2NDlkYTE4YTI4MzM4MWZlMWEiLCJleHAiOjE2ODI2Mzc0NTQsImlhdCI6MTY3NzQ1MzQ1NCwiaXNzIjoienRoeXVuLmNuIiwic3ViIjoidXNlciB0b2tlbiJ9.T2I6eAfBbZkVUIruniSHrx5-X5efUNXZ174AUNATc8A",
      gatewayid: "tests",
    };
  },
  methods: {},
};
</script>

<style lang="less">
html {
  overflow-x: auto;
  overflow-y: scroll;
}

#app {
  top: 0;
  width: 100%;
  height: 100%;

  .head {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: rgb(84, 92, 100);
  }

  .left {
    position: fixed;
    left: 0;
    top: 0;
    width: 210px;
    height: 100%;
    background: rgb(48, 65, 86);
  }

  .ctx {
    position: relative;
    width: calc(100% - 210px);
    margin-left: 210px;
    margin-top: 60px;

    .ctx-top {
      width: 100%;
      height: calc(50px + 34px);

      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      background: #fff;
    }

    .ctx-view {
      width: calc(100%);
      height: 100%;
      padding: 20px;
    }
  }
}
</style>
