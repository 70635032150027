<template>
  <div id="MANUAL">
    <div class="site-header">
      <div class="item-box">
        <div v-for="(item, index) in headerItem" :key="index" :class="{ item: true, 'item-cur': idxCur.header === index }"
          @click="idxCurhandle('header', index)">
          {{ item.name }}
        </div>
      </div>
      <div class="title">
        {{ gateway.list === undefined ? "" : gateway.list.name }}
      </div>
    </div>
    <div v-if="gateway.module2 !== undefined && gateway.data !== undefined" class="site-content">
      <div v-if="headerItem[idxCur.header].id === 'config'" class="config">
        <span class="area">分组列表 【 {{ gateway.module2.device.length }}台 】</span>
        <div class="device-list">
          <div v-if="gateway.module2.device.length === 0" class="item">
            <div class="name">暂无</div>
          </div>
          <div v-for="(item, index) in gateway.module2.device" :key="index"
            :class="{ item: true, 'item-cur': idxCur.device === index }" @click="idxCurhandle('device', index)"
            @contextmenu.prevent="">
            <span v-if="item.drive == 1">通用</span>
            <span v-if="item.drive == 2">一体化</span>
            <span v-if="item.drive == 3">乡镇厂-每日巡检-黄龙溪厂</span>
            <span v-if="item.drive == 4">乡镇厂-每日巡检-彭镇厂1号线</span>
            <span v-if="item.drive == 5">乡镇厂-每日巡检-彭镇厂2号线</span>
            <span v-if="item.drive == 6">乡镇厂-每日巡检-黄水厂</span>
            <span v-if="item.drive == 7">乡镇厂-每日巡检-金桥厂</span>
            <span v-if="item.drive == 8">乡镇厂-每日巡检-胜利厂</span>

            <span v-if="item.drive == 18">乡镇厂-生产统计-黄龙溪厂</span>
            <span v-if="item.drive == 19">乡镇厂-生产统计-彭镇厂</span>
            <span v-if="item.drive == 20">乡镇厂-生产统计-黄水厂</span>
            <span v-if="item.drive == 21">乡镇厂-生产统计-金桥厂</span>
            <span v-if="item.drive == 22">乡镇厂-生产统计-胜利厂</span>

            <span v-if="item.drive == 10">一体化-MBR</span>
            <span v-if="item.drive == 11">一体化-AO</span>
            <span v-if="item.drive == 12">怡心湖泵站</span>
            <span v-if="item.drive == 13">一体化-生态湿地</span>
            <span v-if="item.drive == 14">一体化-MBBR</span>

            <span v-if="item.drive == 15">怡心湖泵站巡检</span>
            <span v-if="item.drive == 16">普通泵站巡检</span>
            <span v-if="item.drive == 17">泵站/一体化用电统计</span>

            <div class="name">{{ item.name }}</div>
          </div>
        </div>
        <div v-if="
          deviceCache !== undefined &&
          gateway.module2.device.length > 0
        ">

          <span class="area">采集点 【 {{ deviceCache.point.length }}个 】</span>
          <div class="point-list" @contextmenu.prevent="">
            <div class="list-body">
              <div v-for="(item, index) in option[1].point" :key="index"
                :class="{ column: true, columnMax: item.id == 'name' || item.id == 'unit', columnAuto: item.id != 'name' && item.id != 'unit' }">
                <div class="column-head">{{ item.name }}</div>
                <div v-for="(im, idx) in deviceCache.point" :key="idx" class="column-item" :class="
                  classHandle(
                    'optionPoint',
                    item,
                    im[item.id],
                    pointCache[idx][item.id]
                  )
                " @contextmenu.prevent="menurightHandle(!pointMenu.enable, idx)">
                  <input v-if="item.way === 'input'" type="text" :style="'width:' + (item.width + 10) + 'px'" :value="
                    im[item.id]
                  " @keyup.enter="enterHandle($event, item.type, im, item.id)"
                    @blur="enterHandle($event, item.type, im, item.id)">
                  <select v-if="item.way === 'select'" :style="'width:' + (item.width + 10) + 'px'" :value="im[item.id]"
                    @change="enterHandle($event, item.type, im, item.id)">
                    <option v-for="(i, x) in item.list" :key="x" :value="i.value">
                      {{ i.key }}
                    </option>
                  </select>
                </div>
              </div>
              <div v-show="pointMenu.enable" class="pointMenu" :style="'top:' + (35 + pointMenu.idx * 35) + 'px'">
                <div class="menu-mask" @contextmenu.prevent="menurightHandle(false, 0)"
                  @click="menurightHandle(false, 0)" />
                <div class="menu-body">
                  <div v-show="deviceCache.point.length > 1 && pointMenu.idx !== 0" :class="{
                    item: true,
                    front: true,
                    'item-show': pointMenu.itemShow,
                  }" @click="editHandle('point-movefront')">
                    前移
                  </div>
                  <div v-show="
                    deviceCache.point.length > 1 &&
                    pointMenu.idx < deviceCache.point.length - 1
                  " :class="{ item: true, behind: true, 'item-show': pointMenu.itemShow, }"
                    @click="editHandle('point-movebehind')">
                    后移
                  </div>
                  <div :class="{
                    item: true,
                    front: true,
                    'item-show': pointMenu.itemShow,
                  }" @click="editHandle('point-insertfront')">
                    前插入
                  </div>
                  <div :class="{
                    item: true,
                    behind: true,
                    'item-show': pointMenu.itemShow,
                  }" @click="editHandle('point-insertbehind')">
                    后插入
                  </div>
                  <div :class="{
                    item: true,
                    delete: true,
                    'item-show': pointMenu.itemShow,
                  }" @click="editHandle('point-delete')">
                    删除
                  </div>
                </div>
              </div>
            </div>
            <div class="list-bottom" @click="editHandle('point-create')">
              + 添加
            </div>
          </div>
        </div>

        <div class="config-funcBox">
          <div v-show="edit.enable">
            <div class="item error" @click="editHandle('cancel')">取消</div>
            <div class="item correct" @click="editHandle('define')">保存</div>
          </div>
          <div v-show="!edit.enable">
            <div v-if="getCopyShow()" v-show="false" class="item" @click="editHandle('device-copy')">
              复制采集点
            </div>
            <div v-show="idxCur.device >= 0" class="item error" @click="editHandle('device-delete')">
              删除分组
            </div>
            <div class="item" @click="editHandle('device-update')">
              修改名称
            </div>
            <div v-show="gateway.module2.device.length < 1" class="item correct" @click="editHandle('device-create')">
              新建分组
            </div>
            <div v-show="idxCur.device >= 0 && false" class="item" @click="editHandle('config-export')">
              表格导出
            </div>
            <div v-show="idxCur.device >= 0 && false" class="item">
              <label for="configImport" style="cursor: pointer;">表格导入</label>
              <input v-show="false" id="configImport" type="file" accept=".xlsx"
                @change="editHandle('config-import', $event)">
            </div>
            <div class="item" @click="editHandle('config-download')">
              下发配置
            </div>
          </div>
        </div>
      </div>
      <div v-if="headerItem[idxCur.header].id === 'historydata'" class="historydata">
        <div class="header">
          <span>变量名称</span>
          <span>当前数值</span>
          <!-- <span>当前状态</span> -->
          <span>更新时间</span>
        </div>
        <div v-for="(item, index) in gateway.data.list" :key="index">
          <div :class="{
            item: true,
            'item-cur': idxCur.historydata === index,
            'item-err': realtime[item.dataID].status2 < 1,
          }" @click="idxCurhandle('historydata', idxCur.historydata === index ? -1 : index)">
            <span>{{ item.name }}</span>
            <span>{{
              realtime[item.dataID].value2 === null
              ? "暂无"
              : (getDataValue(item, realtime[item.dataID].value2))
            }}</span>
            <!-- <span>{{
                  realtime[item.dataID].status2 === null
                    ? "暂无"
                    : statusType[1 - realtime[item.dataID].status2]
                }}</span> -->
            <span>{{
              realtime[item.dataID].time2 === null
              ? "暂无"
              : getDatetime(realtime[item.dataID].time2)
            }}</span>
          </div>
          <div v-if="idxCur.historydata === index" class="box">
            <div class="timer">
              <div class="btn" @click="dataHistory(timer.start, timer.end, true)">
                刷新
              </div>
              <div class="btn delete">清空</div>
              <div class="none">
                {{
                  history.source.length > 1
                  ? "共 " + (history.source.length - 1) + " 个点"
                  : "暂无数据"
                }}
              </div>
              <DatePicker v-if="timer.type === 'day'" :value="new Date()" type="date" placeholder="Select date"
                style="width: 200px" @on-change="dateHandle" />
              <DatePicker v-if="timer.type === 'daymany'" type="daterange" split-panels placeholder="Select date"
                style="width: 200px" @on-change="dateHandle" />
              <RadioGroup v-model="timer.type" style="margin-left: 20px">
                <Radio label="day">天</Radio>
                <Radio label="daymany">范围</Radio>
              </RadioGroup>
            </div>
            <div id="historyEchart" />
          </div>
        </div>
      </div>
      <div v-if="headerItem[idxCur.header].id === 'report'" class="report">
        <div class="header">
          <span>变量名称</span>
          <span>上次数值</span>
          <span>此次数值</span>
          <div v-for="(item, index) in gateway.data.list" :key="index">
          </div>
        </div>
        <div v-for="(item, index) in reportCache" :key="index">
          <div :class="{ item: true, 'item-err': item.value == undefined && reportEnable == true }"
            :style="{ 'margin-top': isBranch(index) ? '15px' : '0' }">
            <span>{{ item.name }}</span>
            <span>{{
              realtime[item.dataid].value2 === undefined
              ? "暂无"
              : (getDataValue2(item, realtime[item.dataid].value2))
            }}</span>
            <span>
              <Form :label-width="70">
                <Input :style="isAuto(item.name) ? 'width: 110px' : 'width: 180px'" v-if="item.type == 1"
                  v-model="item.value" @on-change="autoHandle(item.name)" :placeholder="'单位：' + item.unit" />
                <Button :disabled="isDisable(item.name)" style="margin-left:10px" v-if="isAuto(item.name)"
                  @click="reportAuto(item.name)">生成</Button>
                <RadioGroup v-if="item.type == 2" v-model="item.value" type="button" button-style="solid">
                  <Radio v-for="(item, index) of item.selt" :label="index" :key="index">{{ item }}</Radio>
                </RadioGroup>
              </Form>
            </span>
          </div>

        </div>
        <div :class="{ item: true }" style="margin:10px 0">
          <span>日期</span>
          <span>
            {{ reportTimePrev }}
          </span>
          <span>
            <Input v-model="reportTime" style="width:180px" />
          </span>
        </div>
        <div :class="{ item: true }" style="height: 200px;padding-top: 30px;padding-button:140px">
          <span>
            <Button :size="'large'" icon="md-checkmark" type="primary" @click="reportHandle()"> 提交 </Button>
          </span>
        </div>
      </div>
      <div v-if="headerItem[idxCur.header].id === 'historydata2'" class="historydata2">
        <div class="function">
          <span>历史列表</span>
          <Select v-model="reportSelect.present" style="width:260px">
            <Option v-for="item in reportSelect.list" :value="item.value" :key="item.value">{{ item.label }}</Option>
          </Select>
          <Button style="margin-left:10px" @click="reportQuery"> 查询 </Button>
          <Button style="margin-left:10px" @click="reportUpdate"> 刷新列表 </Button>
        </div>
        <div>
          <div class="header">
            <span>变量名称</span>
            <span>数值</span>
          </div>
          <div v-for="(item, index) in gateway.data.list" :key="index">
            <div :class="{
              item: true,
              'item-cur': idxCur.historydata === index,
              'item-err': realtime2[item.dataID].status2 < 1,
            }">
              <span>{{ item.name }}</span>
              <span>{{
                realtime2[item.dataID].value2 === null
                ? "暂无"
                : (getDataValue(item, realtime2[item.dataID].value2))
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Modal v-model="modal.enable" class-name="vertical-center-modal" :title="modal.title" :loading="modal.loading"
      draggable sticky scrollable width="380" @on-ok="modalHandle()">
      <Form v-if="modal.object === 'device-create'" :label-width="70">
        <FormItem label="名称">
          <Input v-model="modal.option.name" />
        </FormItem>
        <FormItem label="驱动协议">
          <Select v-model="modal.option.drive">
            <Option :value="1">通用</Option>
            <Option :value="2">一体化</Option>
            <Option :value="3">乡镇厂-每日巡检-黄龙溪厂</Option>
            <Option :value="4">乡镇厂-每日巡检-彭镇厂1号线</Option>
            <Option :value="5">乡镇厂-每日巡检-彭镇厂2号线</Option>
            <Option :value="6">乡镇厂-每日巡检-黄水厂</Option>
            <Option :value="7">乡镇厂-每日巡检-金桥厂</Option>
            <Option :value="8">乡镇厂-每日巡检-胜利厂</Option>

            <Option :value="18">乡镇厂-生产统计-黄龙溪厂</Option>
            <Option :value="19">乡镇厂-生产统计-彭镇厂</Option>
            <Option :value="20">乡镇厂-生产统计-黄水厂</Option>
            <Option :value="21">乡镇厂-生产统计-金桥厂</Option>
            <Option :value="22">乡镇厂-生产统计-胜利厂</Option>

            <Option :value="10">一体化-MBR</Option>
            <Option :value="11">一体化-AO</Option>
            <Option :value="12">怡心湖泵站</Option>
            <Option :value="13">一体化-生态湿地</Option>
            <Option :value="14">一体化-MBBR</Option>

            <Option :value="15">怡心湖泵站巡检</Option>
            <Option :value="16">普通泵站巡检</Option>
            <Option :value="17">泵站/一体化用电统计</Option>


          </Select>
        </FormItem>
      </Form>
      <Form v-if="modal.object === 'device-update'" :label-width="70">
        <FormItem label="当前名称">
          <Input v-model="modal.option.nameCur" disabled />
        </FormItem>
        <FormItem label="新名称">
          <Input v-model="modal.option.name" />
        </FormItem>
      </Form>
      <Form v-if="modal.object === 'device-delete'" :label-width="70">
        <FormItem label="名称">
          <Input v-model="modal.option.name" />
        </FormItem>
      </Form>
      <Form v-if="modal.object === 'mqtt-write'" :label-width="70">
        <FormItem label="变量">
          <Input disabled v-model="modal.option.data" />
        </FormItem>
        <FormItem label="数值" v-if="modal.option.type == 1">
          <Input v-model="modal.option.value" :placeholder="'单位：' + modal.option.unit" />
        </FormItem>
        <FormItem label="数值" v-if="modal.option.type == 2">
          <RadioGroup v-model="modal.option.value" type="button">
            <Radio v-for="(item, index) of modal.option.selt" :label="index" :key="index">{{ item }}</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem label="备注说明">
          <div>{{ modal.option.explain }}</div>
        </FormItem>
      </Form>
      <Form v-if="modal.object === 'device-copy'" :label-width="70">
        <FormItem label="目标网关">
          <Select v-model="modal.option.gateway" @on-change="getDeviceList()">
            <Option v-for="(item, index) in modal.option.gatewayList" :key="index" :value="index">
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="目标设备">
          <Select v-model="modal.option.device">
            <Option v-for="(item, index) in modal.option.deviceList" :key="index" :value="index">
              {{ item.name }}
            </Option>
          </Select>
        </FormItem>
      </Form>

    </Modal>
  </div>
</template>

<script>
import Vue from "vue";
import "normalize.css";
import axios from "axios";
import eazyDB from "./eazydb.js";

const lodash = require('lodash');

// iview //
// babel.config.js
// "plugins": [
//   ["import", {
//     "libraryName": "view-design",
//     "libraryDirectory": "src/components"
//   }]
// ]
import "view-design/dist/styles/iview.css";
import {
  Modal,
  Form,
  FormItem,
  Input,
  Select,
  Option,
  Message,
  Notice,
  RadioGroup,
  Button,
  Radio,
  DatePicker,
} from "view-design";
import { point_list } from "./config.js"
Vue.prototype.$Message = Message;
Vue.prototype.$Notice = Notice;
Vue.prototype.$Modal = Modal;

// echart //
// import * as echarts from "echarts/core";
// import { GridComponent, TooltipComponent } from "echarts/components";
// import { LineChart } from "echarts/charts";
// import { CanvasRenderer } from "echarts/renderers";

// echarts.use([GridComponent, LineChart, CanvasRenderer, TooltipComponent]);

import * as echarts from "echarts";

import mqtt from "mqtt";

export default {
  components: {
    Modal,
    Form,
    Input,
    FormItem,
    Select,
    Option,
    Button,
    RadioGroup,
    Radio,
    DatePicker,
  },
  props: {
    url: { type: String, default: "" },
    token: { type: String, default: "" },
    gatewayid: { type: String, default: "" },
    writeEnable: { type: Boolean, default: true },
  },
  data() {
    return {
      mqttClient: null,
      mqttStatus: false,

      database: undefined,

      gateway: {
        info: undefined,
        data: undefined,
        module2: undefined,
      },

      realtime: {},
      realtime2: {},
      history: { source: [], name: "数值" },
      system: { cpu: 0, mem: 0, disk: 0, net: 0, data: 0, err: 0 },

      echart: {
        client: null,
        option: {
          animationDuration: 600,
          grid: {
            left: 70,
            right: 50,
            top: 30,
            buttom: 50,
          },
          xAxis: {
            type: "time",
            boundaryGap: false,
            splitLine: {
              show: false,
            },
          },
          yAxis: {
            yAxis: {
              type: "value",
              max: function (value) {
                if (value.min - (value.max - value.min) / 3 < 0) {
                  return (value.max * 1.25).toFixed(3);
                } else {
                  return (value.max + (value.max - value.min) / 3).toFixed(3);
                }
              },
              min: function (value) {
                return value.min - (value.max - value.min) / 3 > 0
                  ? (value.min - (value.max - value.min) / 3).toFixed(3)
                  : 0;
              },
            },
          },
          tooltip: {
            axisPointer: {
              type: "cross",
            },
            order: "valueDesc",
            trigger: "axis",
            formatter(params) {
              var date = new Date(params[0].data[0]);
              var y = date.getFullYear();
              var m = date.getMonth() + 1;
              var d = date.getDate();
              var h = date.getHours();
              var i = date.getMinutes();
              var s = date.getSeconds();

              m = m < 10 ? "0" + m : m;
              d = d < 10 ? "0" + d : d;
              h = h < 10 ? "0" + h : h;
              i = i < 10 ? "0" + i : i;
              s = s < 10 ? "0" + s : s;
              var result =
                y +
                "-" +
                m +
                "-" +
                d +
                " " +
                h +
                ":" +
                i +
                ":" +
                s +
                "</br>- - - - - - -</br>";
              var dotHtml_h =
                '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:';
              var dtHtml_t = '"></span>';
              params.forEach(function (item) {
                if (item.data[2] < 1) {
                  item.color = "#ce3535";
                  item.data[1] = "异常";
                }
                result +=
                  dotHtml_h +
                  item.color +
                  dtHtml_t +
                  '<span style="display:inline-block;margin-right:6px">' +
                  item.data[3] +
                  '</span><span style="display:inline-block;float:right">' +
                  item.data[1] +
                  "</span>" +
                  "</br>";
              });
              return result;
            },
          },
          dataset: [
            { id: "dataset_raw", source: [] },
            {
              id: "数值",
              fromDatasetId: "dataset_raw",
              transform: {
                type: "filter",
                config: {
                  and: [{ dimension: "data", "=": "数值" }],
                },
              },
            },
          ],
          series: [
            {
              type: "line",
              name: "数值",
              datasetId: "数值",
              showSymbol: false,
              encode: {
                x: "time",
                y: "value",
              },
              itemStyle: {
                borderType: "solid",
                borderCap: "round",
              },
              lineStyle: {
                color: "rgba(84, 112, 198, 1)",
                type: "dotted",
                cap: "round",
              },
              areaStyle: {
                color: "rgba(84, 112, 198, 0.2)",
              },
            },
          ],
        },
      },
      timer: { type: "day", start: 0, end: 0 },

      idxCur: { header: 0, monitor: -1, device: 0, historydata: -1 },

      headerItem: [
        { id: "config", name: "采集配置" },
        { id: "historydata", name: "实时数据" },
        { id: "report", name: "数据上报" },
        { id: "historydata2", name: "历史数据" },
      ],

      statusType: ["正常", "点异常", "设备异常"],

      func: {
        vpn: {
          status: -1,
        },
      },

      option: {
        1: {
          config: [],
          point: [
            {
              id: "name",
              way: "input",
              type: "string",
              name: "数据名",
              unit: "",
              list: [],
              width: 100,
              check: function (val) {
                return true;
              },
            },
            {
              id: "type",
              way: "select",
              type: "number",
              name: "类型",
              unit: "",
              list: [
                { key: "输入", value: 1 },
                { key: "选择", value: 2 },
              ],
              width: 100,
              check: function (val) {
                return true;
              },
            },
            {
              id: "unit",
              way: "input",
              type: "string",
              name: "单位",
              unit: "",
              list: [],
              width: 100,
              check: function (val) {
                return true;
              },
            },
            {
              id: "explain",
              way: "input",
              type: "string",
              name: "备注说明",
              unit: "",
              list: [],
              width: 100,
              check: function (val) {
                return true;
              },
            },
          ],
          configTemplate: {},
          pointTemplate: {
            dataid: "",
            unit: "",
            type: 1,
            explain: "无",
          },
        },
      },

      pointList: point_list,

      deviceCache: undefined,
      pointCache: undefined,
      reportCache: [],
      reportTime: 0,
      reportTimePrev: 0,
      reportEnable: false,
      reportSelect: {
        present: 0,
        list: [],
      },

      pointMenu: { enable: false, idx: 0, bodyShow: false, itemShow: false },

      edit: { enable: false },

      modal: {
        enable: false,
        title: "",
        object: "",
        loading: false,
        option: {},
      },

      interval: { client: null, timer: 0 },
    };
  },
  created() {
    this.databaseInit();
    this.mqttConnect(this.gatewayid);
    this.realtimeInterval(3000, 100);
  },
  methods: {
    idxCurhandle(obj, idx) {
      this.$set(this.idxCur, obj, idx);
      switch (obj) {
        case "header":
          this.idxCur.device = 0;
          this.idxCur.historydata = -1;
          this.idxCur.monitor = -1;
          this.interval.timer = 19970801;
          this.reportTime = this.timetrans(Math.round(new Date() / 1000));
          this.reportEnable = false;
          for (let index in this.reportCache) {
            this.reportCache[index].value = undefined;
          }
          if (this.reportCache.length > 0) {
            this.dataHistoryList(this.reportCache[idx].dataid);
          }
          this.dataRealtime();
        // eslint-disable-next-line
        case "device":
          if (this.edit.enable) {
            return;
          }
          this.deviceCache = lodash.cloneDeep(
            this.gateway.module2.device[this.idxCur.device]
          );
          this.pointCache = lodash.cloneDeep(this.deviceCache.point);
          this.pointCc = lodash.cloneDeep(this.pointCache);
          this.edit.enable = false;
          this.pointMenu = {
            enable: false,
            idx: 0,
            bodyShow: false,
            itemShow: false,
          };
          setTimeout(() => {
            this.deviceCache = lodash.cloneDeep(
              this.gateway.module2.device[this.idxCur.device]
            );
            this.pointCache = lodash.cloneDeep(this.deviceCache.point);
            this.pointCc = lodash.cloneDeep(this.pointCache);
            this.edit.enable = false;
            this.pointMenu = {
              enable: false,
              idx: 0,
              bodyShow: false,
              itemShow: false,
            };
          }, 100);
          break;
        case "historydata":
          if (idx !== -1) {
            setTimeout(() => {
              this.timer.end = parseInt(new Date().getTime() / 1000);
              this.timer.start =
                new Date(
                  (this.timer.end - (this.timer.end % 86400) - 28800) * 1000
                ).getTime() / 1000;
              this.dataHistory(this.timer.start, this.timer.end);
            }, 100);
          } else {
            this.timer.type = "day";
          }
          break;
      }
    },
    classHandle(object, option, value, valueOld) {
      switch (object) {
        case "optionConfig": {
          const result = option.check(value) && value !== "";
          return {
            name: true,
            error: !result,
            correct: result && valueOld !== value,
          };
        }
        case "optionPoint": {
          const result = option.check(value) && value !== "";
          return {
            "column-item": true,
            error: !result,
            correct: result && valueOld !== value,
          };
        }
      }
    },
    enterHandle(event, type, object, key) {
      switch (type) {
        case "string":
          if (object[key] !== event.target.value) {
            this.edit.enable = true;
          }
          this.$set(object, key, event.target.value);
          if (key == "name") {
            this.$set(object, "dataid", event.target.value);
          }
          break;
        case "number":
          {
            const val = parseFloat(event.target.value);
            if (object[key] !== val) {
              this.edit.enable = true;
            }
            this.$set(object, key, isNaN(val) ? 0 : val);
            this.$set(event.target, "value", isNaN(val) ? 0 : val);
            if (event.type !== "blur") {
              event.currentTarget.blur();
            }
          }
          break;
      }
    },
    menurightHandle(enable, idx) {
      this.pointMenu.enable = enable;
      this.pointMenu.idx = idx;
      if (enable) {
        setTimeout(() => {
          this.pointMenu.itemShow = true;
        }, 25);
      } else {
        this.pointMenu.itemShow = false;
      }
    },
    editHandle(object, val, obj) {
      let pointC = null;
      switch (object) {
        case "define":
          // if (this.getPointCount() > 64) {
          //   this.$Message.error("采集点数量限制64");
          //   return;
          // }
          this.dataUpdate();
          this.deviceDefine();
          break;
        case "cancel":
          this.edit.enable = false;
          this.deviceCache = lodash.cloneDeep(
            this.gateway.module2.device[this.idxCur.device]
          );
          this.pointCache = lodash.cloneDeep(this.deviceCache.point);
          break;
        case "device-create":
          this.modal.title = "新建分组";
          this.modal.loading = true;
          this.modal.object = object;
          this.modal.option = { name: "统计工单", drive: 1 };
          this.modal.enable = true;
          break;
        case "device-update":
          if (this.gateway.module2.device.length === 0) {
            return;
          }
          this.modal.title = "修改分组";
          this.modal.loading = true;
          this.modal.object = object;
          this.modal.option = { name: "", nameCur: this.deviceCache.name };
          this.modal.enable = true;
          break;
        case "device-copy":
          this.modal.title = "复制采集点";
          this.modal.loading = true;
          this.modal.object = object;
          this.modal.option = {
            gateway: "",
            gatewayList: [],
            device: "",
            deviceList: [],
            dataList: [],
          };
          this.modal.enable = true;
          this.getGatewayList();
          break;
        case "device-delete":
          if (this.gateway.module2.device.length === 0) {
            return;
          }
          this.modal.title = "删除分组";
          this.modal.loading = true;
          this.modal.object = object;
          this.modal.option = { name: this.deviceCache.name };
          this.modal.enable = true;
          break;
        case "mqtt-write":
          this.modal.title = "写数据";
          this.modal.loading = true;
          this.modal.object = object;
          this.modal.option = { data: val, value: "" };

          // let obj = this.getDataInfo(item.dataID);
          let info = this.getDataInfo(obj.dataID);

          if (info != null) {
            this.modal.option.explain = info.explain;
            this.modal.option.unit = info.unit;

            this.modal.option.type = info.type;
            this.modal.option.selt = info.unit.split(" / ");
            this.modal.option.seltlen = this.modal.option.selt.length;
          } else {
            this.modal.option.explain = "";
            this.modal.option.unit = "";
          }

          // let info = this.getDataInfo(obj.dataID);

          // if (info != null) {
          //   this.modal.option.explain = info.explain;
          //   this.modal.option.unit = info.unit;
          // } else {
          //   this.modal.option.explain = "";
          //   this.modal.option.unit = "";
          // }

          this.modal.enable = true;
          break;
        case "point-create":
          {
            const dataID = "";
            const point = lodash.cloneDeep(this.option[1].pointTemplate);
            pointC = lodash.cloneDeep(point);

            point.data = dataID;
            for (const idx in pointC) {
              pointC[idx] = null;
            }

            this.deviceCache.point.push(point);
            this.pointCache.push(pointC);

            this.edit.enable = true;
          }
          break;
        case "point-insertfront":
          {
            const dataID = "";
            const point = lodash.cloneDeep(this.option[1].pointTemplate);
            pointC = lodash.cloneDeep(point);

            point.data = dataID;
            for (const idx in pointC) {
              pointC[idx] = null;
            }

            this.deviceCache.point.splice(this.pointMenu.idx, 0, point);
            this.pointCache.splice(this.pointMenu.idx, 0, pointC);

            this.pointMenu.idx++;
            this.edit.enable = true;
          }
          break;
        case "point-insertbehind":
          {
            const dataID = "";
            const point = lodash.cloneDeep(this.option[1].pointTemplate);
            pointC = lodash.cloneDeep(point);

            point.data = dataID;
            for (const idx in pointC) {
              pointC[idx] = null;
            }

            this.deviceCache.point.splice(this.pointMenu.idx + 1, 0, point);
            this.pointCache.splice(this.pointMenu.idx + 1, 0, pointC);

            this.edit.enable = true;
          }
          break;
        case "point-movefront":
          {
            const point = lodash.cloneDeep(
              this.deviceCache.point[this.pointMenu.idx]
            );
            this.$set(
              this.deviceCache.point,
              this.pointMenu.idx,
              lodash.cloneDeep(this.deviceCache.point[this.pointMenu.idx - 1])
            );
            this.$set(this.deviceCache.point, this.pointMenu.idx - 1, point);

            const point2 = lodash.cloneDeep(
              this.pointCache[this.pointMenu.idx]
            );
            this.$set(
              this.pointCache,
              this.pointMenu.idx,
              lodash.cloneDeep(this.pointCache[this.pointMenu.idx - 1])
            );
            this.$set(this.pointCache, this.pointMenu.idx - 1, point2);
            this.pointMenu.idx--;
            this.edit.enable = true;
          }
          break;
        case "point-movebehind":
          {
            const point = lodash.cloneDeep(
              this.deviceCache.point[this.pointMenu.idx]
            );
            this.$set(
              this.deviceCache.point,
              this.pointMenu.idx,
              lodash.cloneDeep(this.deviceCache.point[this.pointMenu.idx + 1])
            );
            this.$set(this.deviceCache.point, this.pointMenu.idx + 1, point);

            const point2 = lodash.cloneDeep(
              this.pointCache[this.pointMenu.idx]
            );
            this.$set(
              this.pointCache,
              this.pointMenu.idx,
              lodash.cloneDeep(this.pointCache[this.pointMenu.idx + 1])
            );
            this.$set(this.pointCache, this.pointMenu.idx + 1, point2);
            this.pointMenu.idx++;
            this.edit.enable = true;
          }
          break;
        case "point-delete":
          this.deviceCache.point.splice(this.pointMenu.idx, 1);
          this.pointCache.splice(this.pointMenu.idx, 1);

          this.pointMenu.enable = false;
          this.edit.enable = true;
          break;
        case "config-export":
          break;
        case "config-import":
          break;
        case "config-download":
          this.$Modal.confirm({
            title: "下发配置到",
            content: this.gateway.list.name,
            onOk: () => {
              this.mqttPublish(
                "/dtdp/cmd/config/" + this.gatewayid,
                JSON.stringify({ device: this.gateway.module2.device })
              );
            },
          });

          break;
      }
    },
    modalHandle() {
      switch (this.modal.object) {
        case "device-create":
          this.deviceCreate();
          break;
        case "device-update":
          this.deviceUpdate();
          break;
        case "device-copy":
          {
            this.modal.loading = false;
            let exist = 0;
            for (const item of this.modal.option.deviceList[
              this.modal.option.device
            ].point) {
              for (const idx in this.modal.option.dataList) {
                if (item.data === this.modal.option.dataList[idx].dataID) {
                  exist++;
                  break;
                }
              }
            }
            // if (exist + this.getPointCount() > 64) {
            //   this.$Message.error("采集点数量限制64");
            //   setTimeout(() => {
            //     this.modal.loading = true;
            //   }, 100);
            //   return;
            // }
            for (const item of this.modal.option.deviceList[
              this.modal.option.device
            ].point) {
              for (const idx in this.modal.option.dataList) {
                if (item.data === this.modal.option.dataList[idx].dataID) {
                  this.deviceCache.point.push(lodash.cloneDeep(item));
                  this.pointCache.push(lodash.cloneDeep(item));
                  break;
                }
              }
            }
            this.modal.enable = false;
            this.edit.enable = exist > 0;
            this.modal.object = "";
            this.$Message.success("共复制" + exist + "个数据点");
          }
          break;
        case "device-delete":
          for (const idx in this.gateway.module2.device) {
            if (
              this.gateway.module2.device[idx].name === this.modal.option.name
            ) {
              this.deviceDelete();
              setTimeout(() => {
                this.dataUpdate();
              }, 500);
              break;
            }
          }
          break;
        case "mqtt-write":
          this.modal.loading = false;
          if (
            /^\d+(\.\d+)?$/.test(this.modal.option.value) ||
            /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/.test(
              this.modal.option.value
            )
          ) {
            this.$Message.success("写数据成功");

            this.interval.timer = 1;

            this.modal.loading = true;
            this.modal.enable = false;
          } else {
            this.$Message.error("请填入数字");
            setTimeout(() => {
              this.modal.loading = true;
            }, 100);
          }
          break;
      }
    },
    dateHandle(date) {
      switch (this.timer.type) {
        case "day":
          this.timer.start = new Date(date + " 00:00:00").getTime() / 1000;
          this.timer.end = new Date(date + " 23:59:59").getTime() / 1000;
          break;
        case "daymany":
          this.timer.start = new Date(date[0] + " 00:00:00").getTime() / 1000;
          this.timer.end = new Date(date[1] + " 23:59:59").getTime() / 1000;
          break;
      }
      this.dataHistory(this.timer.start, this.timer.end);
    },
    reportHandle() {
      {
        let r_p = this.reportCache;
        this.reportCache = [];
        this.reportCache = r_p;
      }

      this.reportTime = this.reportTime.trim();
      this.reportTime = this.reportTime.replace(/\s+/gi, " ");

      let data = this.getDateFromString(this.reportTime);

      if (typeof data == "string") {
        this.$Message.error({
          content: "日期格式错误",
          duration: 5,
        });
        return;
      }

      this.reportEnable = true;

      for (let idx in this.reportCache) {
        if (this.reportCache[idx].value == undefined) {
          this.$Message.error({
            content: "请填完所有数值",
            duration: 5,
          });
          return;
        }
        if (
          !/^\d+(\.\d+)?$/.test(this.reportCache[idx].value) &&
          !/^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/.test(
            this.reportCache[idx].value
          )
        ) {
          this.$Message.error({
            content: this.reportCache[idx].name + "：请填入数字",
            duration: 5,
          });
          return;
        }
      }

      let dataObj = { t: Math.round(data / 1000), dl: [] };

      for (let idx in this.reportCache) {
        dataObj.dl.push({
          id: this.reportCache[idx].dataid,
          s: 1,
          v: this.reportCache[idx].value * 1,
        });
      }

      this.commandPublish(
        "/dtdp/data/" + this.gatewayid,
        JSON.stringify(dataObj)
      );
    },

    commandPublish(topic, msg) {
      axios({
        method: "post",
        baseURL: this.url,
        url: "/go/gateway/command2",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: this.token,
        },
        data: {
          topic,
          msg,
        },
      }).then((res) => {
        if (res.data.code === 200) {
          this.$Message.success("提交成功");

          let idx = 0;
          let inter = setInterval(() => {
            this.dataRealtime();
            if (idx >= 3) {
              clearInterval(inter);
            }
            idx++;
          }, 3000);
        } else {
          this.$Message.error("提交失败");
        }
      });
    },

    historyEchartUpdate() {
      this.echart.option.dataset[1].id = this.history.name;
      this.echart.option.dataset[1].transform.config.and[0]["="] =
        this.history.name;
      this.echart.option.series[0].name = this.history.name;
      this.echart.option.series[0].datasetId = this.history.name;

      this.echart.client = echarts.init(
        document.getElementById("historyEchart")
      );
      this.echart.option.dataset[0].source = this.history.source;

      this.echart.client.setOption(this.echart.option);
    },

    realtimeInterval(cycle, freq) {
      this.interval.clent = setInterval(() => {
        this.interval.timer += freq;
        if (this.interval.timer > cycle) {
          this.interval.timer = 0;

          switch (this.headerItem[this.idxCur.header].id) {
            case "monitor":
              break;
            case "historydata":
              this.dataRealtime();
              break;
            case "control":
          }
        }
      }, freq);
    },

    databaseInit() {
      this.database = eazyDB.create(this.url);
      this.database.connect("tolink", this.token, (err) => {
        if (err) {
          console.log("[databaseInit]", err);
        } else {
          this.gatewayRead("list");
        }
      });
    },
    gatewayRead(object) {
      this.database.findOne(
        "gateway_" + object,
        object === "list"
          ? { gatewayID: this.gatewayid }
          : {
            _id: this.gateway.list[object === "module2" ? "module" : object],
          },
        (err, res) => {
          if (err) {
            console.log("[gatewayRead]", err);
          } else {
            this.gateway[object] = res;
            switch (object) {
              case "list":
                this.gatewayRead("info");
                this.gatewayRead("data");
                this.gatewayRead("module2");
                break;
              case "module2":
                if (this.gateway.module2.device === undefined) {
                  this.gateway.module2.device = [];
                }
                if (this.gateway.module2.device.length === 0) {
                  this.idxCur.device = -1;
                  this.deviceCache = [];
                  this.pointCache = [];
                  this.reportCache = [];
                  this.pointCc = [];
                } else {
                  this.idxCur.device = 0;
                  if (this.gateway.module2.device.length > 0) {
                    for (let idx in this.gateway.module2.device) {
                      for (let idx2 in this.gateway.module2.device[idx].point) {
                        this.gateway.module2.device[idx].point[idx2].dataid =
                          this.gateway.module2.device[idx].point[idx2].name;
                      }
                    }
                    this.deviceCache = lodash.cloneDeep(
                      this.gateway.module2.device[this.idxCur.device]
                    );
                    this.pointCache = lodash.cloneDeep(this.deviceCache.point);
                    this.pointCc = lodash.cloneDeep(this.pointCache);
                    this.reportCache = [];
                    if (this.gateway.module2.device.length > 0) {
                      this.reportCache = lodash.cloneDeep(
                        this.gateway.module2.device[0].point
                      );
                      for (let idx in this.reportCache) {
                        this.reportCache[idx]["value"] = undefined;
                        this.reportCache[idx]["selt"] =
                          this.reportCache[idx].unit.split(" / ");
                        this.reportCache[idx]["seltlen"] =
                          this.reportCache[idx]["selt"].length;
                      }
                    }
                  }
                }
                break;
              case "data":
                this.realtime = {};
                this.realtime2 = {};

                for (const idx in this.gateway.data.list) {
                  this.realtime[this.gateway.data.list[idx].dataID] = {
                    name: this.gateway.data.list[idx].name,
                    value: undefined,
                    status: undefined,
                    value2: undefined,
                    status2: undefined,
                    time2: undefined,
                  };
                  this.realtime2[this.gateway.data.list[idx].dataID] = {
                    name: this.gateway.data.list[idx].name,
                    value: undefined,
                    status: undefined,
                    value2: undefined,
                    status2: undefined,
                    time2: undefined,
                  };
                }
                break;
            }
          }
        }
      );
    },
    deviceCreate() {
      this.modal.option["config"] = {};
      this.modal.option["point"] = lodash.cloneDeep(this.pointList[this.modal.option.drive]);

      for (const idx in this.gateway.module2.device) {
        if (this.gateway.module2.device[idx].name === this.modal.option.name) {
          this.$Message.error("分组名已存在");
          this.modal.loading = false;
          setTimeout(() => {
            this.modal.loading = true;
          }, 100);
          return;
        }
      }

      this.database.updateOne(
        "gateway_module2",
        { _id: this.gateway.module2._id },
        {
          $addToSet: {
            device: this.modal.option,
          },
        },
        (err, res) => {
          this.modal.loading = false;
          if (err) {
            this.$Message.error(err);
            setTimeout(() => {
              this.modal.loading = true;
            }, 100);
          } else {
            this.$Message.success("新建成功");
            this.modal.enable = false;
            const last = this.gateway.module2.device.length;
            const interval = setInterval(() => {
              if (this.gateway.module2.device[last] !== undefined) {
                this.idxCurhandle("device", last);
                clearInterval(interval);
              }
            }, 100);
            this.gatewayRead("module2");
          }
        }
      );

      let list = [];

      for (let idx in this.modal.option["point"]) {
        list.push({
          dataID: this.modal.option["point"][idx].dataid,
          name: this.modal.option["point"][idx].dataid,
          dbconfig: -1,
        });
      }

      this.database.updateOne(
        "gateway_data",
        {
          _id: this.gateway.data._id,
        },
        {
          $set: { list },
        },
        (err, res) => {
          if (err) {
            this.$Message.error(err);
          } else {
            this.gatewayRead("data");
          }
        }
      );
    },
    deviceUpdate() {
      for (const idx in this.gateway.module2.device) {
        if (this.gateway.module2.device[idx].name === this.modal.option.name) {
          this.$Message.error("分组名已存在");
          this.modal.loading = false;
          setTimeout(() => {
            this.modal.loading = true;
          }, 100);
          return;
        }
      }

      this.database.updateOne(
        "gateway_module2",
        {
          _id: this.gateway.module2._id,
          "device.name": this.modal.option.nameCur,
        },
        {
          $set: {
            "device.$.name": this.modal.option.name,
          },
        },
        (err, res) => {
          this.modal.loading = false;
          if (err) {
            this.$Message.error(err);
            setTimeout(() => {
              this.modal.loading = true;
            }, 100);
          } else {
            this.$Message.success("修改成功");
            this.modal.enable = false;
            this.gatewayRead("module2");
          }
        }
      );
    },
    deviceDelete() {
      let exist = false;
      for (const idx in this.gateway.module2.device) {
        if (this.gateway.module2.device[idx].name === this.modal.option.name) {
          exist = true;
          break;
        }
      }
      if (!exist) {
        this.$Message.error("分组不存在");
        this.modal.loading = false;
        setTimeout(() => {
          this.modal.loading = true;
        }, 100);
        return;
      }

      this.database.updateOne(
        "gateway_module2",
        {
          _id: this.gateway.module2._id,
        },
        {
          $pull: {
            device: { name: this.modal.option.name },
          },
        },
        (err, res) => {
          this.modal.loading = false;
          if (err) {
            this.$Message.error(err);
            setTimeout(() => {
              this.modal.loading = true;
            }, 100);
          } else {
            this.$Message.success("删除成功");
            this.modal.enable = false;
            this.gatewayRead("module2");
          }
        }
      );
    },
    dataUpdate() {
      const list = [];

      for (const idx in this.gateway.module2.device) {
        if (idx === this.idxCur.device.toString()) {
          for (const pnt of this.deviceCache.point) {
            list.push({
              dataID: pnt.dataid,
              name: pnt.name,
              dbconfig: -1,
            });
          }
        } else {
          for (const pnt of this.gateway.module2.device[idx].point) {
            list.push({
              dataID: pnt.dataid,
              name: pnt.name,
              dbconfig: -1,
            });
          }
        }
      }

      this.database.updateOne(
        "gateway_data",
        {
          _id: this.gateway.data._id,
        },
        {
          $set: { list },
        },
        (err, res) => {
          if (err) {
            this.$Message.error(err);
          } else {
            this.gatewayRead("data");
          }
        }
      );
    },
    deviceDefine() {
      this.database.updateOne(
        "gateway_module2",
        {
          _id: this.gateway.module2._id,
          "device.name": this.deviceCache.name,
        },
        {
          $set: {
            "device.$.config": this.deviceCache.config,
            "device.$.point": this.deviceCache.point,
          },
        },
        (err, res) => {
          this.modal.loading = false;
          if (err) {
            this.$Message.error(err);
          } else {
            this.$Message.success("设置成功");
            this.edit.enable = false;
            this.gatewayRead("module2");
          }
        }
      );
    },
    dataHistory(Datestart, Dateend, update) {
      if (this.echart.client !== null) {
        this.echart.client.dispose();
        this.echart.client = null;
      }
      if (update) {
        this.timer.end = parseInt(new Date().getTime() / 1000);
      }
      axios({ // 历史数据
        method: "post",
        baseURL: this.url,
        url: "/go/gateway/getdata",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: this.token,
        },
        params: {
          GatewayID: this.gatewayid,
          Function: "historydata",
          Formula: "x",
          Datestart,
          Pagenum: 1,
          Pagesize: 9999999999,
          Dateend,
        },
        data: {
          dataID: this.gateway.data.list[this.idxCur.historydata].dataID,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            if (res.data.return.status === null) {
              null;
            }
            res.data.return.values =
              res.data.return.values === null ? [] : res.data.return.values;
            res.data.return.status =
              res.data.return.status === null ? [] : res.data.return.status;
            res.data.return.times =
              res.data.return.times === null ? [] : res.data.return.times;

            this.history.source = [["time", "value", "status", "data"]];
            for (const idx in res.data.return.values) {
              this.history.source.push([
                res.data.return.times[idx] * 1000,
                res.data.return.values[idx],
                res.data.return.status[idx],
                "数值",
              ]);
            }

            this.historyEchartUpdate();
          }
        })
        .catch((err) => {
          console.log("[dataHistory]", err);
        });
    },
    dataClearHistory() {
      this.$Modal.warning({
        title: "删除确认",
        content: "此操作会清空该数据点的所有历史数据",
        onOk: () => {
          axios({
            method: "post",
            baseURL: this.url,
            url: "/go/gateway/data",
            headers: {
              "Content-Type": "application/json; charset=UTF-8",
              Authorization: this.token,
            },
            params: {
              GatewayID: this.gatewayid,
              Function: "deletedb",
            },
            data: {
              dataID: this.gateway.data.list[this.idxCur.historydata].dataID,
            },
          })
            .then((res) => {
              if (res.data.code === 200) {
                this.$Message.success(res.data.msg);
              } else {
                this.$Message.error(res.data.msg);
              }
              this.dataHistory(this.timer.start, this.timer.end);
            })
            .catch((err) => {
              console.log("[dataClearHistory]", err);
            });
        },
      });
    },
    dataRealtime() {
      const data = [];
      for (const idx in this.realtime) {
        data.push(idx);
      }
      axios({ // 实时数据
        method: "post",
        baseURL: this.url,
        url: "/go/gateway/getdata",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: this.token,
        },
        params: {
          GatewayID: this.gatewayid,
          Function: "realtimedata",
        },
        data,
      })
        .then((res) => {
          if (res.data.code === 200) {
            res.data.return.points =
              res.data.return.points === null ? [] : res.data.return.points;
            res.data.return.values =
              res.data.return.values === null ? [] : res.data.return.values;
            res.data.return.status =
              res.data.return.status === null ? [] : res.data.return.status;
            res.data.return.times =
              res.data.return.times === null ? [] : res.data.return.times;

            for (const idx in res.data.return.points) {
              this.realtime[res.data.return.points[idx]].value2 =
                res.data.return.values[idx];
              this.realtime[res.data.return.points[idx]].status2 =
                res.data.return.status[idx];
              this.realtime[res.data.return.points[idx]].time2 =
                res.data.return.times[idx];

              this.reportTimePrev = this.timetrans(res.data.return.times[idx]);
            }

            const test = this.realtime;
            this.realtime = {};
            this.realtime = test;
          }
        })
        .catch((err) => {
          console.log("[dataRealtime]", err);
        });
    },
    dataHistoryList(dataID) {
      axios({
        method: "post",
        baseURL: this.url,
        url: "/go/gateway/getdata",
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
          Authorization: this.token,
        },
        params: {
          GatewayID: this.gatewayid,
          Function: "historydata",
          Formula: "x",
          Datestart: 1640966400,
          Dateend: 4796640000,
          Pagenum: 1,
          Pagesize: 9999999999,
        },
        data: {
          dataID,
        },
      })
        .then((res) => {
          if (res.data.code === 200) {
            this.reportSelect.list = [];
            for (let idx in res.data.return.times) {
              this.reportSelect.list.push({
                value: res.data.return.times[idx],
                label: this.timetrans(res.data.return.times[idx]),
              });
            }
          }
        })
        .catch((err) => {
          console.log("[dataRealtime]", err);
        });
    },
    reportQuery() {
      for (let idx in this.realtime2) {
        this.realtime2[idx].value2 = undefined;

        const test = this.realtime2;
        this.realtime2 = {};
        this.realtime2 = test;
      }
      axios({
        method: "post",
        baseURL: this.url,
        url: "/go/gateway/getdata",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          Authorization: this.token,
        },
        params: {
          GatewayID: this.gatewayid,
          Function: "datalist",
          Time: this.reportSelect.present,
        },
        data: {},
      }).then((res) => {
        if (res.data.code === 200) {
          res.data.return.dataids =
            res.data.return.dataids === null ? [] : res.data.return.dataids;
          res.data.return.values =
            res.data.return.values === null ? [] : res.data.return.values;
          res.data.return.status =
            res.data.return.status === null ? [] : res.data.return.status;

          for (const idx in res.data.return.dataids) {
            this.realtime2[res.data.return.dataids[idx]].value2 =
              res.data.return.values[idx];
            this.realtime2[res.data.return.dataids[idx]].status2 =
              res.data.return.status[idx];
          }

          const test = this.realtime2;
          this.realtime2 = {};
          this.realtime2 = test;
        }
      });
    },
    reportUpdate() {
      if (this.reportCache.length > 0) {
        this.dataHistoryList(this.reportCache[0].dataid);
      }
    },
    isDisable(name) {
      switch (name) {
        case 'PAC_固体_库存量':
        case '乙酸钠_醋酸钠_固体_库存量':
        case '葡萄糖_固体_库存量':
        case '絮凝剂_固体_库存量':
        case '次氯酸钠_液体_库存量':
        case '柠檬酸_固体_库存量':
        case '氢氧化钠_固体_库存量':
        case '乙酸钠_液体_库存量':
          if (this.realtime[name].value2 == undefined)
            return true
          break

        case "进水情况_进水量":
        case "出水情况_产水量":
        case '处理水情况_处理水量':
        case '自来水情况_用水量':
          if (this.realtime[name.split('_')[0] + "_累计流量"].value2 == undefined)
            return true
          break

        case '电量情况_用电量':
          if (this.realtime[name.split('_')[0] + "_累计电量"].value2 == undefined)
            return true
          break
      }

      return false;
    },
    reportAuto(name) {
      let object_name = "", handle = "";
      let isElec = false;
      switch (name) {
        case "进水情况_进水量":
        case "出水情况_产水量":
        case '处理水情况_处理水量':
        case '自来水情况_用水量':
          handle = "acc"
          object_name = name.split('_')[0] + "_累计流量";
          break
        case '电量情况_用电量':
          handle = "acc"
          isElec = true
          object_name = name.split('_')[0] + "_累计电量";
          break

        case 'PAC_固体_总使用量':
        case '乙酸钠_醋酸钠_固体_总使用量':
        case '葡萄糖_固体_总使用量':
        case '絮凝剂_固体_总使用量':
        case '次氯酸钠_液体_总使用量':
        case '柠檬酸_固体_总使用量':
        case '氢氧化钠_固体_总使用量':
        case '乙酸钠_液体_总使用量':
          handle = "stats"
          object_name = name.replace("_总使用量", "");
          break

        case 'PAC_固体_库存量':
        case '乙酸钠_醋酸钠_固体_库存量':
        case '葡萄糖_固体_库存量':
        case '絮凝剂_固体_库存量':
        case '次氯酸钠_液体_库存量':
        case '柠檬酸_固体_库存量':
        case '氢氧化钠_固体_库存量':
        case '乙酸钠_液体_库存量':
          handle = "diff"
          object_name = name.replace("_库存量", "");
          break
      }

      switch (handle) {
        case "acc":
          {
            let prev = this.realtime[object_name].value2;
            prev = prev == undefined ? 0 : prev;

            let next = undefined;
            for (let idx in this.reportCache) {
              if (this.reportCache[idx].dataid == object_name) {
                next = this.reportCache[idx].value;
                break;
              }
            }

            if (next != undefined) {
              for (let idx in this.reportCache) {
                if (this.reportCache[idx].dataid == name) {
                  let newpoint = lodash.cloneDeep(this.reportCache[idx]);

                  newpoint.value = next * 1 - prev * 1;

                  if (isElec) {
                    switch (this.gateway.module2.device[0].drive) {
                      case 18:
                        newpoint.value *= 600;
                        break;
                      case 19:
                        newpoint.value *= 800;
                        break;
                      case 22:
                        newpoint.value *= 60;
                        break;
                      case 21:
                        newpoint.value *= 300;
                        break;
                      case 20:
                        newpoint.value *= 50;
                        break;
                    }
                  }

                  this.$set(this.reportCache, idx, newpoint);
                  break;
                }
              }
            }
          }
          break;
        case "stats":
          {

            let wsc = undefined;
            for (let idx in this.reportCache) {
              if (this.reportCache[idx].dataid == object_name + '_污水厂使用量') {
                wsc = this.reportCache[idx].value;
                break;
              }
            }

            let yth = undefined;
            for (let idx in this.reportCache) {
              if (this.reportCache[idx].dataid == object_name + '_一体化使用量') {
                yth = this.reportCache[idx].value;
                break;
              }
            }

            if (wsc != undefined && yth != undefined) {
              for (let idx in this.reportCache) {
                if (this.reportCache[idx].dataid == name) {

                  let newpoint = lodash.cloneDeep(this.reportCache[idx]);
                  newpoint.value = wsc * 1 + yth * 1;
                  this.$set(this.reportCache, idx, newpoint);
                  break;
                }
              }
            }
          }
          break;
        case "diff":
          {
            let dhl = undefined;
            for (let idx in this.reportCache) {
              if (this.reportCache[idx].dataid == object_name + '_到货量') {
                dhl = this.reportCache[idx].value;
                break;
              }
            }

            let zsyl = undefined;
            for (let idx in this.reportCache) {
              if (this.reportCache[idx].dataid == object_name + '_总使用量') {
                zsyl = this.reportCache[idx].value;
                break;
              }
            }

            let kcl_prev = this.realtime[name].value2;

            if (dhl != undefined && zsyl != undefined && kcl_prev != undefined) {
              for (let idx in this.reportCache) {
                if (this.reportCache[idx].dataid == name) {
                  let newpoint = lodash.cloneDeep(this.reportCache[idx]);

                  newpoint.value = kcl_prev * 1 + dhl * 1 - zsyl * 1;
                  this.$set(this.reportCache, idx, newpoint);
                  break;
                }
              }
            }
          }
          break;
      }
    },
    autoHandle(name) {
      let object_name = "";
      switch (name) {
        case '进水情况_累计流量':
          object_name = "进水情况_进水量";
          break;
        case "出水情况_累计流量":
          object_name = "出水情况_产水量";
          break;
        case '处理水情况_累计流量':
          object_name = "处理水情况_处理水量";
          break;
        case '自来水情况_累计流量':
          object_name = "自来水情况_用水量";
          break
        case '电量情况_累计电量':
          object_name = "电量情况_用电量";
          break
      }
      if (object_name != "") {
        this.reportAuto(object_name)
        return
      }

      object_name = "";
      let object_group = name.split('_');
      if (object_group.length > 0) {
        switch (object_group[object_group.length - 1]) {
          case "污水厂使用量":
            object_name = name.replace("_污水厂使用量", "");
            break;
          case "一体化使用量":
            object_name = name.replace("_一体化使用量", "");
            break;
          case "到货量":
            object_name = name.replace("_到货量", "");
            break;
        }
      }
      if (object_name != '') {
        this.reportAuto(object_name + "_总使用量")
        this.reportAuto(object_name + "_库存量")
      }
    },
    isAuto(name) {
      switch (name) {
        case '进水情况_进水量':
        case '出水情况_产水量':
        case '处理水情况_处理水量':
        case '自来水情况_用水量':
        case '电量情况_用电量':

        case 'PAC_固体_总使用量':
        case '乙酸钠_醋酸钠_固体_总使用量':
        case '葡萄糖_固体_总使用量':
        case '絮凝剂_固体_总使用量':
        case '次氯酸钠_液体_总使用量':
        case '柠檬酸_固体_总使用量':
        case '氢氧化钠_固体_总使用量':
        case '乙酸钠_液体_总使用量':

        case 'PAC_固体_库存量':
        case '乙酸钠_醋酸钠_固体_库存量':
        case '葡萄糖_固体_库存量':
        case '絮凝剂_固体_库存量':
        case '次氯酸钠_液体_库存量':
        case '柠檬酸_固体_库存量':
        case '氢氧化钠_固体_库存量':
        case '乙酸钠_液体_库存量':
          return true;
      }

      return false;
    },
    isBranch(index) {
      if (index == 0) {
        return false
      }

      let prev = this.reportCache[index - 1].name.split('_');
      let next = this.reportCache[index].name.split('_');


      if (prev.length == 0 || next.length == 0) {
        return false
      }


      if (prev[0] != next[0]) {
        return true
      }

      return false
    },

    getGatewayList() {
      this.database.findMany(
        "gateway_list",
        {
          model: "FY-BOX",
        },
        {
          index: 1,
          size: 100000,
        },
        (err, res) => {
          if (!err) {
            if (res !== null) {
              this.modal.option.gatewayList = res;
            }
          }
        }
      );
    },
    getDeviceList() {
      this.database.findOne(
        "gateway_module2",
        {
          _id: this.modal.option.gatewayList[this.modal.option.gateway].module,
        },
        (err, res) => {
          if (!err) {
            if (!("device" in res)) {
              res.device = [];
            }
            if (res.device === null) {
              res.device = [];
            }
            this.modal.option.deviceList = res.device;
          }
        }
      );
      this.database.findOne(
        "gateway_data",
        {
          _id: this.modal.option.gatewayList[this.modal.option.gateway].data,
        },
        (err, res) => {
          if (!err) {
            if (res.list === null) {
              res.list = [];
            }
            this.modal.option.dataList = res.list;
          }
        }
      );
    },
    getCopyShow() {
      if (this.idxCur.device === -1) {
        return false;
      }
      if (this.gateway.module2.device[this.idxCur.device].point.length !== 0) {
        return false;
      }
      return true;
    },

    mqttConnect(gatewayID) {
      // 执行连接
      var url = this.url.split("//")[1];
      if (url.split(":").length === 2) {
        url = url.split(":")[0];
      }
      this.mqttClient = mqtt.connect("ws://" + url + ":8083/mqtt", {
        connectTimeout: 10000,
        clientId:
          new Date().getTime() + Math.random().toString(11).substring(2),
        username: "digate",
        password: "8981409",
        clean: true,
      });
      // 连接成功回调
      this.mqttClient.on("connect", (e) => {
        this.mqttStatus = true;
        this.mqttSubscribe("fbox/setret/" + gatewayID);
      });
      // 接收消息回调
      this.mqttClient.on("message", (topic, message) => {
        this.mqttMessageHandle(topic, this.Utf8ToStr(message));
      });
    },
    mqttDisconnect() {
      if (this.mqttClient.connected) {
        try {
          this.mqttClient.end(false);
          this.mqttClient = {
            connected: false,
          };
        } catch (err) {
          err;
        }
      }
    },
    mqttSubscribe(topic) {
      this.mqttClient.subscribe(topic, { qos: 0 }, (err) => {
        err;
      });
    },
    mqttPublish(topic, payload) {
      if (this.mqttStatus) {
        this.mqttClient.publish(topic, payload, 0, (err) => {
          err;
        });
      }
    },
    mqttMessageHandle(topic, message) { // mqtt 数据返回
      const tp = topic.split("/");

      let msg = JSON.parse(message);

      if (tp.length === 3) {
        switch (tp[1]) {
          case "setret":
            this.$Notice["info"]({
              title: "网关推送",
              desc: msg.flexem_message,
            });
            break;
        }
      }
    },

    getDataInfo(dataid) {
      for (let idx in this.gateway.module2.device) {
        for (let idx2 in this.gateway.module2.device[idx].point) {
          if (this.gateway.module2.device[idx].point[idx2].dataid == dataid) {
            return this.gateway.module2.device[idx].point[idx2];
          }
        }
      }
      return null;
    },
    getDataValue(item, value) {
      let obj = this.getDataInfo(item.dataID);
      let val = obj.unit.split(" / ");

      if (value == undefined) {
        return "";
      }

      if (value <= val.length - 1) {
        return val[value * 1];
      } else {
        return value + " " + obj.unit + "";
      }
    },
    getDataValue2(item, value) {
      let obj = this.getDataInfo(item.dataid);
      let val = obj.unit.split(" / ");

      if (value == undefined) {
        return "";
      }

      if (value <= val.length - 1) {
        return val[value * 1];
      } else {
        return value + " " + obj.unit + "";
      }
    },
    func_handle_vpn(cmd) {
      this.mqttPublish("/dtdp/cmd/vpn/" + this.gatewayid, cmd);
    },

    Utf8ToStr(array) {
      var out, i, len, c;
      var char2, char3;
      out = "";
      len = array.length;
      i = 0;
      while (i < len) {
        c = array[i++];
        switch (c >> 4) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
            // 0xxxxxxx
            out += String.fromCharCode(c);
            break;
          case 12:
          case 13:
            // 110x xxxx 10xx xxxx
            char2 = array[i++];
            out += String.fromCharCode(((c & 0x1f) << 6) | (char2 & 0x3f));
            break;
          case 14:
            // 1110 xxxx 10xx xxxx 10xx xxxx
            char2 = array[i++];
            char3 = array[i++];
            out += String.fromCharCode(
              ((c & 0x0f) << 12) | ((char2 & 0x3f) << 6) | ((char3 & 0x3f) << 0)
            );
            break;
        }
      }

      return out;
    },
    getDatetime(time) {
      if (!time) {
        return "";
      }
      var now = time > 0 ? new Date(time * 1000) : new Date();
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var day = now.getDate();
      var hh = now.getHours();
      var mm = now.getMinutes();
      var ss = now.getSeconds();
      var clock = year + "-";
      if (month < 10) clock += "0";
      clock += month + "-";
      if (day < 10) clock += "0";
      clock += day + " ";
      if (hh < 10) clock += "0";
      clock += hh + ":";
      if (mm < 10) clock += "0";
      clock += mm + ":";
      if (ss < 10) clock += "0";
      clock += ss;
      return clock;
    },
    getPointCount() {
      let count = 0;

      for (const idx in this.gateway.module2.device) {
        if (idx === this.idxCur.device) {
          for (const _idx in this.deviceCache.point) {
            _idx;
            count++;
          }
        } else {
          for (const _idx in this.gateway.module2.device[idx].point) {
            _idx;
            count++;
          }
        }
      }

      return count;
    },
    s2ab(s) {
      if (typeof ArrayBuffer !== "undefined") {
        const buf = new ArrayBuffer(s.length);
        const view = new Uint8Array(buf);
        for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
        return buf;
      } else {
        const buf = new Array(s.length);
        for (let j = 0; j !== s.length; ++j) buf[j] = s.charCodeAt(j) & 0xff;
        return buf;
      }
    },
    timetrans(date) {
      var date = new Date(date * 1000); //如果date为10位需要乘1000
      var Y = date.getFullYear() + "-";
      var M =
        (date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1) + "-";
      var D =
        (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";
      var h =
        (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      var m =
        (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
        ":";
      var s =
        date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return Y + M + D + h + m + s;
    },
    getDateFromString(str) {
      var reg = /^(\d+)-(\d+)-(\d+) (\d+):(\d+):(\d+)/;
      var s = str.match(reg);
      var result = "";
      if (s) {
        result = new Date(s[1], s[2] - 1, s[3], s[4], s[5], s[6]);
      }
      return result;
    },
  },
};
</script>

<style lang="less" scoped>
#MANUAL {
  width: 100%;
  height: 100%;
}

.correct {
  color: #fff !important;
  background: rgb(103, 189, 103) !important;

  input,
  select {
    color: #fff !important;
    background: rgb(103, 189, 103) !important;
  }

  option {
    color: #000;
    background: #fff;
  }
}

.error {
  color: #fff !important;
  background: rgb(189, 103, 103) !important;

  input,
  select {
    color: #fff !important;
    background: rgb(189, 103, 103) !important;
  }
}

.site-header {
  display: flex;
  height: 40px;
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
  margin-bottom: 18px;
  padding: 0 10px;

  .item-box {
    float: left;

    .item {
      display: inline-block;
      height: 40px;
      padding: 0 40px;
      line-height: 40px;
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
      color: #888;
      font-size: 14px;
      font-weight: 400;
      user-select: none;
      cursor: pointer;
    }

    .item:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    }

    .item:active {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }

    .item-cur {
      background: #eee;
    }
  }

  .title {
    flex: 1;
    height: 40px;
    padding: 0 60px;
    line-height: 40px;
    color: #999;
    font-size: 14px;
    font-weight: 500;
    text-align: right;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  }
}

.site-content {
  width: 100%;
  padding-bottom: 40px;
}

.monitor {
  display: flex;
  width: 100%;

  .device {
    display: inline-block;
    width: 180px;
    margin-right: 15px;
    height: 640px;
    padding: 0 5px;

    .item {
      margin: 5px;
      padding: 5px;
      color: rgb(24, 22, 22);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.05);
      cursor: pointer;
      transition: all 0.3s ease;

      .name {
        width: 100%;
        margin-bottom: 10px;
        color: #666;
        font-weight: 400;
        font-size: 14px;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);
      }

      .info {
        height: 20px;
        width: 90%;
        color: #888;
        font-size: 12px;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.15);

        .drive {
          float: left;
        }

        .alive {
          float: right;
        }
      }
    }

    .item:hover {
      transform: translateX(5px);
    }

    .online {
      background: rgb(203, 226, 201);
    }

    .item-cur {
      position: relative;
      margin: 14px 0;
      box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.1);

      .mark {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 5px);
        height: calc(100% - 5px);
      }
    }
  }

  .data {
    display: inline-block;
    flex: 1;
    height: 100%;

    .item {
      display: flex;
      margin: 8px;
      padding: 5px;
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.05);
      color: #999;
      transition: all 0.3s ease;
      background: rgb(247, 247, 247);

      span {
        flex: 1;
        text-align: center;
        font-size: 14px;
        font-weight: 300;
      }

      button {
        display: inline-block;
        margin: 0;
        outline: 0;
        border: 0;
        background: rgb(122, 202, 233);
        padding: 3px 10px;
        border-radius: 4px;
        color: #fff;
        transition: all 0.3s ease;
        cursor: pointer;
      }

      button:hover {
        transform: scale(1.03);
      }

      button:active {
        transition: none;
        transform: scale(0.97);
      }
    }

    .item:hover {
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
    }

    .item-err {
      background: rgb(248, 230, 230);
    }

    .title {
      margin: 0px 10px 5px 10px;
      background: #999;
      color: #fff;
      font-size: 16px;
      text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
    }
  }

  .system {
    display: inline-block;
    width: 200px;
    margin: 0 2% 0 15px;
    padding: 0 5px;

    .title {
      height: 30px;
      color: #666;
      font-size: 14px;
      line-height: 30px;
      font-weight: 500;
      // text-align: center;
      text-shadow: 4px 4px 6px rgba(0, 0, 0, 0.1);
    }

    .backcolor {
      background: rgb(245, 245, 245);
      margin: 5px;
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.13);

      .value {
        position: relative;
        height: 21px;
        line-height: 21px;
        color: #444;
        font-size: 12px;
        text-align: center;
        background: rgb(245, 245, 245);

        .perc {
          position: absolute;
          height: 21px;
          top: 0;
          left: 0;
          margin: 0;
          background: rgb(96, 171, 214);
          box-shadow: none;
          transition: all 0.3s ease;
        }

        .num {
          position: absolute;
          width: 100%;
          z-index: 1;
        }
      }
    }
  }
}

.config {
  width: calc(100% - 10px);
  padding: 0 5px;

  .area {
    display: inline-block;
    margin: 5px 0;
    color: #666;
    font-size: 14px;
    text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
  }

  .device-list {
    width: calc(100% - 10px);
    padding: 0 5px;
    margin-bottom: 20px;

    .item {
      position: relative;
      display: inline-block;
      margin-right: 15px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
      cursor: pointer;
      user-select: none;

      .name {
        float: left;
        height: 34px;
        padding: 0 20px;
        line-height: 34px;
        color: #666;
        font-size: 14px;
        font-weight: 400;
      }

      span {
        float: left;
        height: 34px;
        line-height: 34px;
        padding: 0 10px;
        height: 34px;
        background: #fff;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.13);
      }
    }

    .item:hover {
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
    }

    .item:active {
      // transform: scale(0.95);
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
    }

    .item-cur {
      .name {
        color: #fff;
        background: #60abd6;
      }
    }
  }

  .drive-param {
    width: calc(100% - 10px);
    margin-bottom: 20px;

    .item {
      display: inline-block;
      margin: 3px 10px;
      color: #666;
      font-size: 14px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

      .name {
        float: left;
        padding: 6px 10px;
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
        border-right: 2px solid #eee;
      }

      .unit {
        float: right;
        padding: 6px 10px;
      }

      input {
        float: left;
        outline: none;
        border: none;
        padding: 6px 0 6px 10px;
        margin: 0;
        text-align: center;
      }

      input:focus {
        color: #fff;
        background: #333 !important;
      }

      select {
        float: left;
        height: 33px;
        outline: none;
        border: none;
        padding: 0 5px;
        margin: 0;
        text-align: center;
        text-align-last: center;
        cursor: pointer;
      }
    }

    .item:hover {
      box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.15);
    }
  }

  .point-list {
    width: calc(100% - 10px);
    padding: 0 5px;
    margin-bottom: 20px;

    .list-body {
      position: relative;
      display: flex;

      .columnMax {
        width: 25%;
      }

      .columnAuto {
        flex: 1;
      }

      .column {
        font-size: 14px;

        .column-head {
          height: 30px;
          line-height: 30px;
          margin: 0 3px 5px 0;
          text-align: center;
          background: #999;
          color: #fff;
          text-shadow: 3px 3px 8px rgba(0, 0, 0, 0.6);
          user-select: none;
          cursor: pointer;
        }

        .column-item {
          display: flex;
          height: 32px;
          line-height: 32px;
          text-align: center;
          background: rgba(0, 0, 0, 0.03);
          margin: 0 3px 3px 0;
          transition: all 0.3s;

          >* {
            flex: 1;
            margin: 0;
            padding: 0 10px;
            border: none;
            outline: none;
            background: transparent;
            font-size: 12.5px;
            font-weight: 300;
            text-align: center;
            text-align-last: center;
          }

          input:focus {
            color: #fff;
            background: #333 !important;
          }

          select {
            cursor: pointer;
          }
        }

        .column-item:hover {
          transition: none;
          background: rgba(0, 0, 0, 0.07);
          box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.13);
        }
      }

      .pointMenu {
        position: absolute;
        left: 0;
        width: calc(100% - 3px);
        height: 32px;
        overflow: hidden;
        background: rgba(0, 0, 0, 0.4);
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);

        .menu-mask {
          top: 0;
          right: 0;
          left: 0;
          bottom: 0;
          position: fixed;
        }

        .menu-body {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 1;
          width: calc(100% - 20px + 120px);
          height: 32px;
          padding: 0 10px;
          text-align: center;

          .item {
            display: inline-block;
            height: 24px;
            margin: 4px 12px;
            padding: 0 16px;
            color: #fff;
            font-size: 12px;
            line-height: 24px;
            box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
            user-select: none;
            cursor: pointer;
            transition: all 0.4s ease;
          }

          .item:active {
            transition: all 0.2s ease;
          }

          .front {
            background: rgb(95, 175, 206);
          }

          .front:active {
            transform: translateX(-20px) translateY(-4px);
          }

          .behind {
            background: rgb(95, 175, 206);
          }

          .behind:active {
            transform: translateX(-20px) translateY(4px);
          }

          .delete {
            background: rgb(189, 103, 103);
          }

          .delete:active {
            transform: translateX(-17px);
          }
        }

        .item-show {
          transform: translateX(-20px);
        }
      }
    }

    .list-bottom {
      display: inline-block;
      margin: 1px 0;
      width: calc(100% - 3px);
      height: 32px;
      background: rgba(0, 0, 0, 0.03);
      color: #999;
      line-height: 32px;
      text-align: center;
      font-size: 14px;
      text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.3);
      user-select: none;
      cursor: pointer;
      transition: all 0.4s ease;
    }

    .list-bottom:hover {
      box-shadow: 0 0 6px rgba(0, 0, 0, 0.1);
    }

    .list-bottom:active {
      transition: none;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    }
  }

  .config-funcBox {
    position: fixed;
    bottom: 15px;
    right: 60px;
    height: 32px;

    .spacing {
      display: inline-block;
      margin: 0 10px 0 20px;
      color: #999;
      font-weight: 600;
      text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.2);
    }

    .item {
      display: inline-block;
      height: 28px;
      padding: 0 16px;
      margin-left: 10px;
      background: rgb(95, 175, 206);
      box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
      color: #fff;
      font-size: 14px;
      line-height: 28px;
      user-select: none;
      cursor: pointer;
    }

    .item:active {
      transform: scale(0.95);
    }
  }
}

.report {
  width: calc(100% - 10px);
  padding: 0 10px;

  .header {
    display: flex;
    height: 32px;
    background: #999;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 10px;

    span {
      flex: 1;
    }
  }

  .item {
    display: flex;
    // height: 46px;
    padding: 8px 0;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    color: #666;
    background: rgb(247, 247, 247);
    line-height: 30px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 5px;
    cursor: pointer;
    transition: all 0.3s ease;

    span {
      flex: 1;
    }
  }

  .item-err {
    background: rgb(248, 230, 230);
  }
}

.historydata {
  width: calc(100% - 10px);
  padding: 0 10px;

  .header {
    display: flex;
    height: 32px;
    background: #999;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 10px;

    span {
      flex: 1;
    }
  }

  .item {
    display: flex;
    height: 30px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    color: #666;
    background: rgb(247, 247, 247);
    line-height: 30px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 10px;
    cursor: pointer;
    transition: all 0.3s ease;

    span {
      flex: 1;
    }

    button {
      display: inline-block;
      outline: 0;
      border: 0;
      background: rgb(122, 202, 233);
      padding: 0 10px;
      border-radius: 4px;
      color: #fff;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    button:hover {
      transform: scale(1.03);
    }

    button:active {
      transition: none;
      transform: scale(0.97);
    }
  }

  .item-cur {
    transform: scale(1.01);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }

  .item-err {
    background: rgb(248, 230, 230);
  }

  .item:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  .item:active {
    transition: all 0s;
    transform: scale(1.01);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }

  .box {
    width: 100%;
    height: 400px;
    overflow: hidden;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
    background: rgb(235, 235, 235);

    #historyEchart {
      width: 100%;
      height: calc(100% - 52px);
    }

    .timer {
      height: 32px;
      padding: 0 50px;
      margin-top: 20px;
      line-height: 32px;

      .none {
        float: right;
        height: 32px;
        padding: 0 10px;
      }

      .btn {
        float: right;
        height: 26px;
        margin: 3px 5px;
        padding: 0 15px;
        background: rgb(110, 187, 218);
        color: #fff;
        line-height: 26px;
        user-select: none;
        cursor: pointer;
      }

      .btn:active {
        transform: scale(1.05);
      }

      .delete {
        background: rgb(185, 65, 75);
      }
    }
  }
}

.historydata2 {
  width: calc(100% - 10px);
  padding: 0 10px;

  .function {
    margin-bottom: 20px;

    span {
      display: inline-block;
      line-height: 32px;
      height: 32px;
      font-size: 16px;
      margin-right: 10px;
    }

    button {
      display: inline-block;
      outline: 0;
      border: 0;
      background: rgb(122, 202, 233);
      padding: 0 10px;
      border-radius: 4px;
      color: #fff;
      transition: all 0.3s ease;
      cursor: pointer;
    }

    button:hover {
      transform: scale(1.03);
    }

    button:active {
      transition: none;
      transform: scale(0.97);
    }
  }

  .header {
    display: flex;
    height: 32px;
    background: #999;
    color: #fff;
    font-size: 14px;
    line-height: 32px;
    text-align: center;
    margin-bottom: 10px;

    span {
      flex: 1;
    }
  }

  .item {
    display: flex;
    height: 40px;
    box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
    color: #666;
    background: rgb(247, 247, 247);
    line-height: 40px;
    font-weight: 300;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s ease;

    span {
      flex: 1;
    }
  }

  .item-cur {
    transform: scale(1.01);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }

  .item-err {
    background: rgb(248, 230, 230);
  }

  .item:hover {
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
  }

  .item:active {
    transition: all 0s;
    transform: scale(1.01);
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
  }
}

.control {
  display: flex;
  width: 100%;

  .func-box {
    display: inline-block;
    flex: 1;
    height: calc(100% - 100px);
    width: calc(100% - 250px);

    .func-box-item {
      float: left;
      height: 200px;
      width: 300px;
      margin: 10px;
      padding: 10px;
      box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);

      .item-title {
        display: inline-block;
        height: 30px;
        width: 100%;
        line-height: 30px;
        text-align: center;
        background: rgb(247, 247, 247);
        font-size: 18px;
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
      }

      .item-content {
        display: inline-block;
        margin: 10px;
        height: 130px;
        width: calc(100% - 20px);
        display: flex;
        flex-direction: row;

        .item-content-left {
          flex: 1;
          text-align: center;

          img {
            margin-top: 20px;
            height: 60px;
            width: 90px;
          }
        }

        .item-content-right {
          flex: 1;
          padding: 20px 0;

          .item-content-button {
            height: 50%;
            width: 100%;
            text-align: center;
            line-height: 45px;
            font-size: 16px;
            color: #4a5053;
            user-select: none;
          }

          .item-content-button:hover {
            cursor: pointer;
            font-size: 18px;
            background: rgb(242, 242, 242);
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
          }

          .item-content-button:active {
            transform: scale(0.9);
            box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
          }
        }
      }
    }
  }
}

.vertical-center-modal {
  display: flex;
  align-items: center;
  justify-content: center;

  .ivu-modal {
    top: 0;
  }
}
</style>
