export const point_list = {
    2: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain:
                "可以调取任意时间段内的累计流量当日进水量超过设计能力时，自动关联管理人员。（每个站点各异）当出现当日进水量为0或者负数时，自动报错，关联到生产管理人员。",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况",
            dataid: "出水情况",
            unit: "m3",
            type: 1,
            explain: "（故障，关联到生产管理人员）",
        },
        {
            name: "出水情况_累计流量",
            dataid: "出水情况_累计流量",
            unit: "m3",
            type: 1,
            explain:
                "可以调取任意时间段内的累计流量当日进水量超过设计能力时，自动关联管理人员。（每个站点各异）当出现当日进水量为0或者负数时，自动报错，关联到生产管理人员。",
        },
        {
            name: "出水情况_产水量",
            dataid: "出水情况_产水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "格栅机运行情况",
            dataid: "格栅机运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        {
            name: "调节池运行情况_提升泵",
            dataid: "调节池运行情况_提升泵",
            unit: "正常 / 故障",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        {
            name: "调节池运行情况_液位",
            dataid: "调节池运行情况_液位",
            unit: "低 / 高",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        {
            name: "厌氧池运行情况",
            dataid: "厌氧池运行情况",
            unit: "异常 / 正常",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        {
            name: "缺氧池运行情况",
            dataid: "缺氧池运行情况",
            unit: "异常 / 正常",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        // ----------
        {
            name: "好氧池运行情况_曝气",
            dataid: "好氧池运行情况_曝气",
            unit: "异常 / 均匀",
            type: 2,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于10%、高于50%，关联到生产管理人员）",
        },
        {
            name: "好氧池运行情况_污泥色泽",
            dataid: "好氧池运行情况_污泥色泽",
            unit: "黑色 / 黄褐色",
            type: 2,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于10%、高于50%，关联到生产管理人员）",
        },
        {
            name: "好氧池运行情况_沉降比",
            dataid: "好氧池运行情况_沉降比",
            unit: "%",
            type: 1,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于10%、高于50%，关联到生产管理人员）",
        },
        {
            name: "好氧池运行情况_DO",
            dataid: "好氧池运行情况_DO",
            unit: "",
            type: 1,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于10%、高于50%，关联到生产管理人员）",
        },
        // ----------
        {
            name: "膜池运行情况_曝气",
            dataid: "膜池运行情况_曝气",
            unit: "异常 / 均匀",
            type: 2,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于20%、高于90%，关联到生产管理人员）",
        },
        {
            name: "膜池运行情况_污泥色泽",
            dataid: "膜池运行情况_污泥色泽",
            unit: "黑色 / 黄褐色",
            type: 2,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于20%、高于90%，关联到生产管理人员）",
        },
        {
            name: "膜池运行情况_沉降比",
            dataid: "膜池运行情况_沉降比",
            unit: "%",
            type: 1,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于20%、高于90%，关联到生产管理人员）",
        },
        {
            name: "膜池运行情况_膜池液位",
            dataid: "膜池运行情况_膜池液位",
            unit: "m",
            type: 1,
            explain:
                "（曝气异常、污泥色泽黑色，关联到生产管理人员）（沉降比、低于20%、高于90%，关联到生产管理人员）",
        },
        {
            name: "膜在线清洗",
            dataid: "膜在线清洗",
            unit: "异常 / 正常",
            type: 2,
            explain: "（异常，关联到生产管理人员）",
        },
        {
            name: "回流泵运行情况",
            dataid: "回流泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        {
            name: "产水情况",
            dataid: "产水情况",
            unit: "异常 / 正常",
            type: 2,
            explain:
                "（巡检人员通过负压、产水瞬时流量综合判定，确定是否该洗膜）（是，关联到生产管理人员和混合液拉运负责人）",
        },
        {
            name: "产水情况_负压",
            dataid: "产水情况_负压",
            unit: "Mpa",
            type: 1,
            explain:
                "（巡检人员通过负压、产水瞬时流量综合判定，确定是否该洗膜）（是，关联到生产管理人员和混合液拉运负责人）",
        },
        {
            name: "产水情况_产水瞬时流量",
            dataid: "产水情况_产水瞬时流量",
            unit: "m3/h",
            type: 1,
            explain:
                "（巡检人员通过负压、产水瞬时流量综合判定，确定是否该洗膜）（是，关联到生产管理人员和混合液拉运负责人）",
        },
        {
            name: "产水情况_是否该洗膜",
            dataid: "产水情况_是否该洗膜",
            unit: "否 / 是",
            type: 2,
            explain:
                "（巡检人员通过负压、产水瞬时流量综合判定，确定是否该洗膜）（是，关联到生产管理人员和混合液拉运负责人）",
        },
        {
            name: "反洗泵运行情况",
            dataid: "反洗泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "（故障，同步关联机修和生产管理人员）",
        },
        // ----
        {
            name: "生化池风机运行情况_异响",
            dataid: "生化池风机运行情况_异响",
            unit: "无 / 有",
            type: 2,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        {
            name: "生化池风机运行情况_压力",
            dataid: "生化池风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        {
            name: "生化池风机运行情况_加油量",
            dataid: "生化池风机运行情况_加油量",
            unit: "",
            type: 1,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        // ----
        {
            name: "膜池风机运行情况_异响",
            dataid: "膜池风机运行情况_异响",
            unit: "无 / 有",
            type: 2,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        {
            name: "膜池风机运行情况_压力",
            dataid: "膜池风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        {
            name: "膜池风机运行情况_加油量",
            dataid: "膜池风机运行情况_加油量",
            unit: "",
            type: 1,
            explain:
                "（异响，同步关联机修和生产管理人员）加油量，通过定期弹出，或者派送任务的方式，不用每天填写。压力大于0.1Mpa，自动关联到机修和生产管理人员。",
        },
        // ----
        {
            name: "PAC投加系统",
            dataid: "PAC投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "PAC投加系统_加药量",
            dataid: "PAC投加系统_加药量",
            unit: "kg",
            type: 1,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "碳源投加系统",
            dataid: "碳源投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "碳源投加系统_加药量",
            dataid: "碳源投加系统_加药量",
            unit: "kg",
            type: 1,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "次钠投加系统",
            dataid: "次钠投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "次钠投加系统_加药量",
            dataid: "次钠投加系统_加药量",
            unit: "kg",
            type: 1,
            explain:
                "同类药剂，月度自动汇总。厂站建平台的时候，要体现到货量、出（供）货量，与一体化的使用量形成闭环、平衡。",
        },
        {
            name: "紫外线消毒运行情况",
            dataid: "紫外线消毒运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "（故障，关联到生产管理人员）",
        },

        {
            name: "储泥池情况_拉运",
            dataid: "储泥池情况_拉运",
            unit: "否 / 是",
            type: 2,
            explain: "（是，关联到生产管理人员和混合液拉运负责人）",
        },
        {
            name: "环境卫生",
            dataid: "环境卫生",
            unit: "合格 / 不合格",
            type: 2,
            explain:
                "现场地面无垃圾、淤泥、蜘蛛网；草坪无杂草，绿化进行修剪；柜壁柜顶干净。",
        },
        {
            name: "环境安全",
            dataid: "环境安全",
            unit: "合格 / 不合格",
            type: 2,
            explain:
                "现场围栏、门、构筑物、箱体、锁具完好；井盖完好，正常安装；灭火器完好。",
        },
    ],
    3: [
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "氧化沟_中沟溶解氧",
            dataid: "氧化沟_中沟溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "氧化沟_外沟氧化还原电位",
            dataid: "氧化沟_外沟氧化还原电位",
            unit: "mv",
            type: 1,
            explain: "无",
        },
        {
            name: "氧化沟_中沟污泥浓度",
            dataid: "氧化沟_中沟污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "氧化沟_内沟溶解氧",
            dataid: "氧化沟_内沟溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_1_膜池液位",
            dataid: "膜产水区_1_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_2_膜池液位",
            dataid: "膜产水区_2_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_3_膜池液位",
            dataid: "膜产水区_3_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_产水压力_1",
            dataid: "膜产水区_产水压力_1",
            unit: "Kpa",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_产水压力_2",
            dataid: "膜产水区_产水压力_2",
            unit: "Kpa",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_产水压力_3",
            dataid: "膜产水区_产水压力_3",
            unit: "Kpa",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_产水流量_瞬时",
            dataid: "膜产水区_产水流量_瞬时",
            unit: "L/S",
            type: 1,
            explain: "无",
        },
        {
            name: "膜产水区_产水流量_累计",
            dataid: "膜产水区_产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },

        {
            name: "粗格栅运行状态",
            dataid: "粗格栅运行状态",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "污水提升泵_1",
            dataid: "污水提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_2",
            dataid: "污水提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_3",
            dataid: "污水提升泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "螺旋分砂机",
            dataid: "螺旋分砂机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "旋流沉砂器_1",
            dataid: "旋流沉砂器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流沉砂器_2",
            dataid: "旋流沉砂器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "罗茨风机_1",
            dataid: "罗茨风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_2",
            dataid: "罗茨风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "膜格栅_1",
            dataid: "膜格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_2",
            dataid: "膜格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池_提升泵_1",
            dataid: "调节池_提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_提升泵_2",
            dataid: "调节池_提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池_搅拌器_1",
            dataid: "调节池_搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_2",
            dataid: "调节池_搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_3",
            dataid: "调节池_搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "厌氧选择池_搅拌器_1",
            dataid: "厌氧选择池_搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池_搅拌器_2",
            dataid: "厌氧选择池_搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池_搅拌器_3",
            dataid: "厌氧选择池_搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池_搅拌器_4",
            dataid: "厌氧选择池_搅拌器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "氧化沟_曝气风机_1",
            dataid: "氧化沟_曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_曝气风机_2",
            dataid: "氧化沟_曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_1",
            dataid: "氧化沟_表曝机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_2",
            dataid: "氧化沟_表曝机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_3",
            dataid: "氧化沟_表曝机_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_4",
            dataid: "氧化沟_表曝机_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_5",
            dataid: "氧化沟_表曝机_5",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_表曝机_6",
            dataid: "氧化沟_表曝机_6",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_推流器_1",
            dataid: "氧化沟_推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "氧化沟_推流器_2",
            dataid: "氧化沟_推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "内沟回流泵",
            dataid: "内沟回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "外沟回流泵",
            dataid: "外沟回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "膜池回流泵_1",
            dataid: "膜池回流泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池回流泵_2",
            dataid: "膜池回流泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_1",
            dataid: "反洗泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_2",
            dataid: "反洗泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_3",
            dataid: "产水泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "剩余污泥泵_1",
            dataid: "剩余污泥泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "剩余污泥泵_2",
            dataid: "剩余污泥泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_1",
            dataid: "膜池风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_2",
            dataid: "膜池风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },

        {
            name: "除臭系统_循环水泵_1",
            dataid: "除臭系统_循环水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "除臭系统_循环水泵_2",
            dataid: "除臭系统_循环水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "除臭系统_离心风机_1",
            dataid: "除臭系统_离心风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "除臭系统_离心风机_2",
            dataid: "除臭系统_离心风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    4: [
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池ORP",
            dataid: "生化池ORP",
            unit: "mv",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池外沟DO",
            dataid: "生化池外沟DO",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池内沟DO",
            dataid: "生化池内沟DO",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池污泥浓度",
            dataid: "生化池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组液位",
            dataid: "1_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组液位",
            dataid: "2_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组液位",
            dataid: "3_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水压力",
            dataid: "1_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水压力",
            dataid: "2_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水压力",
            dataid: "3_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水流量_瞬时",
            dataid: "1_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水流量_累计",
            dataid: "1_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水流量_瞬时",
            dataid: "2_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水流量_累计",
            dataid: "2_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水流量_瞬时",
            dataid: "3_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水流量_累计",
            dataid: "3_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },

        {
            name: "粗格栅",
            dataid: "粗格栅",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_1",
            dataid: "污水提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_2",
            dataid: "污水提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_3",
            dataid: "污水提升泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "螺旋分砂机",
            dataid: "螺旋分砂机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流沉砂器_1",
            dataid: "旋流沉砂器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_1",
            dataid: "罗茨风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_1",
            dataid: "膜格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_2",
            dataid: "膜格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "中压水泵_1",
            dataid: "中压水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "中压水泵_2",
            dataid: "中压水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "高压水泵_1",
            dataid: "高压水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "高压水泵_2",
            dataid: "高压水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "螺旋压榨机",
            dataid: "螺旋压榨机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_提升泵_1",
            dataid: "调节池_提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_提升泵_2",
            dataid: "调节池_提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_提升泵_3",
            dataid: "调节池_提升泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_1",
            dataid: "调节池_搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_2",
            dataid: "调节池_搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_3",
            dataid: "调节池_搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池_搅拌器_4",
            dataid: "调节池_搅拌器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_1",
            dataid: "厌氧选择池搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_2",
            dataid: "厌氧选择池搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_3",
            dataid: "厌氧选择池搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_4",
            dataid: "厌氧选择池搅拌器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_1",
            dataid: "曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_2",
            dataid: "曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_1",
            dataid: "表曝机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_2",
            dataid: "表曝机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_3",
            dataid: "表曝机_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_4",
            dataid: "表曝机_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_5",
            dataid: "表曝机_5",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "表曝机_6",
            dataid: "表曝机_6",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_1",
            dataid: "推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_2",
            dataid: "推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_3",
            dataid: "推流器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "内回流泵",
            dataid: "内回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "外回流泵",
            dataid: "外回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池回流泵_1",
            dataid: "膜池回流泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池回流泵_2",
            dataid: "膜池回流泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵",
            dataid: "反洗泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗滤器",
            dataid: "反洗滤器",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_3",
            dataid: "产水泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "剩余污泥泵",
            dataid: "剩余污泥泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机",
            dataid: "膜池风机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    5: [
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池ORP",
            dataid: "生化池ORP",
            unit: "mv",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池外沟DO",
            dataid: "生化池外沟DO",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池内沟DO",
            dataid: "生化池内沟DO",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池污泥浓度",
            dataid: "生化池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组液位",
            dataid: "1_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组液位",
            dataid: "2_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组液位",
            dataid: "3_膜组液位",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水压力",
            dataid: "1_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水压力",
            dataid: "2_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水压力",
            dataid: "3_膜组产水压力",
            unit: "KPa",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水流量_瞬时",
            dataid: "1_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜组产水流量_累计",
            dataid: "1_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水流量_瞬时",
            dataid: "2_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜组产水流量_累计",
            dataid: "2_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水流量_瞬时",
            dataid: "3_膜组产水流量_瞬时",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "3_膜组产水流量_累计",
            dataid: "3_膜组产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_1",
            dataid: "厌氧选择池搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_2",
            dataid: "厌氧选择池搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_3",
            dataid: "厌氧选择池搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧选择池搅拌器_4",
            dataid: "厌氧选择池搅拌器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_1",
            dataid: "曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_2",
            dataid: "曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_1",
            dataid: "推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_2",
            dataid: "推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_3",
            dataid: "推流器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "推流器_4",
            dataid: "推流器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "内回流泵",
            dataid: "内回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "外回流泵",
            dataid: "外回流泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池回流泵_1",
            dataid: "膜池回流泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池回流泵_2",
            dataid: "膜池回流泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵",
            dataid: "反洗泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗滤器",
            dataid: "反洗滤器",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_3",
            dataid: "产水泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_3",
            dataid: "产水泵_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "剩余污泥泵",
            dataid: "剩余污泥泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机",
            dataid: "膜池风机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    6: [
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池溶解氧",
            dataid: "生化池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "后缺氧回流量",
            dataid: "后缺氧回流量",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池污泥浓度",
            dataid: "生化池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池溶解氧",
            dataid: "膜池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池污泥浓度",
            dataid: "膜池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜池液位",
            dataid: "1_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜池液位",
            dataid: "2_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "1_产水压力",
            dataid: "1_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "2_产水压力",
            dataid: "2_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_瞬时",
            dataid: "产水流量_瞬时",
            unit: "L/S",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_累计",
            dataid: "产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },

        {
            name: "细格栅_1",
            dataid: "细格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "细格栅_2",
            dataid: "细格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_1",
            dataid: "旋流分砂器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_2",
            dataid: "旋流分砂器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_1",
            dataid: "调节池提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_2",
            dataid: "调节池提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_1",
            dataid: "罗茨风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_2",
            dataid: "罗茨风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_1",
            dataid: "膜格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_2",
            dataid: "膜格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧搅拌器_1",
            dataid: "厌氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_1",
            dataid: "前缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_2",
            dataid: "前缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_1",
            dataid: "好氧池推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_2",
            dataid: "好氧池推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_1",
            dataid: "曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_2",
            dataid: "曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_1",
            dataid: "后缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_2",
            dataid: "后缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧回流泵_1",
            dataid: "后缺氧回流泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧回流泵_2",
            dataid: "后缺氧回流泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_1",
            dataid: "反洗泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_2",
            dataid: "反洗泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_1",
            dataid: "膜池风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_2",
            dataid: "膜池风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    7: [
        {
            name: "集水井液位",
            dataid: "集水井液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池溶解氧",
            dataid: "生化池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "后缺氧回流量",
            dataid: "后缺氧回流量",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池污泥浓度",
            dataid: "生化池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池溶解氧",
            dataid: "膜池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池污泥浓度",
            dataid: "膜池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜池液位",
            dataid: "1_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜池液位",
            dataid: "2_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "1_产水压力",
            dataid: "1_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "2_产水压力",
            dataid: "2_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_瞬时",
            dataid: "产水流量_瞬时",
            unit: "L/S",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_累计",
            dataid: "产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },

        {
            name: "细格栅_1",
            dataid: "细格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "细格栅_2",
            dataid: "细格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "集水井提升泵_1",
            dataid: "集水井提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "集水井提升泵_2",
            dataid: "集水井提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_1",
            dataid: "旋流分砂器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_2",
            dataid: "旋流分砂器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_1",
            dataid: "罗茨风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_2",
            dataid: "罗茨风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_1",
            dataid: "调节池提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_2",
            dataid: "调节池提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_1",
            dataid: "膜格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_2",
            dataid: "膜格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧搅拌器_1",
            dataid: "厌氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧搅拌器_2",
            dataid: "厌氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_1",
            dataid: "前缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_2",
            dataid: "前缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_3",
            dataid: "前缺氧搅拌器_3",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_4",
            dataid: "前缺氧搅拌器_4",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_1",
            dataid: "好氧池推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_2",
            dataid: "好氧池推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_1",
            dataid: "曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_2",
            dataid: "曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_1",
            dataid: "后缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_2",
            dataid: "后缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_1",
            dataid: "反洗泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_2",
            dataid: "反洗泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_1",
            dataid: "膜池风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_2",
            dataid: "膜池风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    8: [
        {
            name: "集水井液位",
            dataid: "集水井液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "调节池液位",
            dataid: "调节池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池溶解氧",
            dataid: "生化池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "后缺氧回流量",
            dataid: "后缺氧回流量",
            unit: "m3/h",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池污泥浓度",
            dataid: "生化池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池溶解氧",
            dataid: "膜池溶解氧",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "膜池污泥浓度",
            dataid: "膜池污泥浓度",
            unit: "mg/L",
            type: 1,
            explain: "无",
        },
        {
            name: "1_膜池液位",
            dataid: "1_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "2_膜池液位",
            dataid: "2_膜池液位",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "1_产水压力",
            dataid: "1_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "2_产水压力",
            dataid: "2_产水压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_瞬时",
            dataid: "产水流量_瞬时",
            unit: "L/S",
            type: 1,
            explain: "无",
        },
        {
            name: "产水流量_累计",
            dataid: "产水流量_累计",
            unit: "m3",
            type: 1,
            explain: "无",
        },

        {
            name: "细格栅_1",
            dataid: "细格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "细格栅_2",
            dataid: "细格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "集水井提升泵_1",
            dataid: "集水井提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "集水井提升泵_2",
            dataid: "集水井提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_1",
            dataid: "旋流分砂器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "旋流分砂器_2",
            dataid: "旋流分砂器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_1",
            dataid: "调节池提升泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池提升泵_2",
            dataid: "调节池提升泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_1",
            dataid: "罗茨风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "罗茨风机_2",
            dataid: "罗茨风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_1",
            dataid: "膜格栅_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜格栅_2",
            dataid: "膜格栅_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧搅拌器_1",
            dataid: "厌氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧搅拌器_2",
            dataid: "厌氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_1",
            dataid: "前缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "前缺氧搅拌器_2",
            dataid: "前缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_1",
            dataid: "好氧池推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池推流器_2",
            dataid: "好氧池推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_1",
            dataid: "曝气风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气风机_2",
            dataid: "曝气风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_1",
            dataid: "后缺氧搅拌器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "后缺氧搅拌器_2",
            dataid: "后缺氧搅拌器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_1",
            dataid: "反洗泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "反洗泵_2",
            dataid: "反洗泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_1",
            dataid: "产水泵_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵_2",
            dataid: "产水泵_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池推流器_1",
            dataid: "膜池推流器_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池推流器_2",
            dataid: "膜池推流器_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_1",
            dataid: "膜池风机_1",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池风机_2",
            dataid: "膜池风机_2",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "离心风机",
            dataid: "离心风机",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
        {
            name: "循环水泵",
            dataid: "循环水泵",
            unit: "正常运行 / 故障 / 停机",
            type: 2,
            explain: "无",
        },
    ],
    18: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_累计流量",
            dataid: "处理水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_处理水量",
            dataid: "处理水情况_处理水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_累计流量",
            dataid: "自来水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_用水量",
            dataid: "自来水情况_用水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_累计电量",
            dataid: "电量情况_累计电量",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_用电量",
            dataid: "电量情况_用电量",
            unit: "kw.h/600",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_好氧池",
            dataid: "SV30_好氧池",
            unit: "%",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_膜池",
            dataid: "SV30_膜池",
            unit: "%",
            type: 1,
            explain: "无",
        },


        {
            name: "PAC_固体_污水厂使用量",
            dataid: "PAC_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_一体化使用量",
            dataid: "PAC_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_到货量",
            dataid: "PAC_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_总使用量",
            dataid: "PAC_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_库存量",
            dataid: "PAC_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_醋酸钠_固体_污水厂使用量",
            dataid: "乙酸钠_醋酸钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_一体化使用量",
            dataid: "乙酸钠_醋酸钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_到货量",
            dataid: "乙酸钠_醋酸钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_总使用量",
            dataid: "乙酸钠_醋酸钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_库存量",
            dataid: "乙酸钠_醋酸钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "葡萄糖_固体_污水厂使用量",
            dataid: "葡萄糖_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_一体化使用量",
            dataid: "葡萄糖_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_到货量",
            dataid: "葡萄糖_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_总使用量",
            dataid: "葡萄糖_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_库存量",
            dataid: "葡萄糖_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "絮凝剂_固体_污水厂使用量",
            dataid: "絮凝剂_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_一体化使用量",
            dataid: "絮凝剂_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_到货量",
            dataid: "絮凝剂_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_总使用量",
            dataid: "絮凝剂_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_库存量",
            dataid: "絮凝剂_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "次氯酸钠_液体_污水厂使用量",
            dataid: "次氯酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_一体化使用量",
            dataid: "次氯酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_到货量",
            dataid: "次氯酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量",
            dataid: "次氯酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量刻度",
            dataid: "次氯酸钠_液体_总使用量刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_库存量",
            dataid: "次氯酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "柠檬酸_固体_污水厂使用量",
            dataid: "柠檬酸_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_一体化使用量",
            dataid: "柠檬酸_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_到货量",
            dataid: "柠檬酸_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_总使用量",
            dataid: "柠檬酸_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_库存量",
            dataid: "柠檬酸_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "氢氧化钠_固体_污水厂使用量",
            dataid: "氢氧化钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_一体化使用量",
            dataid: "氢氧化钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_到货量",
            dataid: "氢氧化钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_总使用量",
            dataid: "氢氧化钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_库存量",
            dataid: "氢氧化钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_液体_污水厂使用量",
            dataid: "乙酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_一体化使用量",
            dataid: "乙酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_到货量",
            dataid: "乙酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用量",
            dataid: "乙酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用刻度",
            dataid: "乙酸钠_液体_总使用刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_库存量",
            dataid: "乙酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
    ],
    19: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_累计流量",
            dataid: "处理水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_处理水量",
            dataid: "处理水情况_处理水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_累计流量",
            dataid: "自来水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_用水量",
            dataid: "自来水情况_用水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_累计电量",
            dataid: "电量情况_累计电量",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_用电量",
            dataid: "电量情况_用电量",
            unit: "kw.h/800",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_好氧池",
            dataid: "SV30_好氧池",
            unit: "%",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_膜池",
            dataid: "SV30_膜池",
            unit: "%",
            type: 1,
            explain: "无",
        },


        {
            name: "PAC_固体_污水厂使用量",
            dataid: "PAC_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_一体化使用量",
            dataid: "PAC_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_到货量",
            dataid: "PAC_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_总使用量",
            dataid: "PAC_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_库存量",
            dataid: "PAC_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_醋酸钠_固体_污水厂使用量",
            dataid: "乙酸钠_醋酸钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_一体化使用量",
            dataid: "乙酸钠_醋酸钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_到货量",
            dataid: "乙酸钠_醋酸钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_总使用量",
            dataid: "乙酸钠_醋酸钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_库存量",
            dataid: "乙酸钠_醋酸钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "葡萄糖_固体_污水厂使用量",
            dataid: "葡萄糖_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_一体化使用量",
            dataid: "葡萄糖_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_到货量",
            dataid: "葡萄糖_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_总使用量",
            dataid: "葡萄糖_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_库存量",
            dataid: "葡萄糖_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "絮凝剂_固体_污水厂使用量",
            dataid: "絮凝剂_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_一体化使用量",
            dataid: "絮凝剂_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_到货量",
            dataid: "絮凝剂_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_总使用量",
            dataid: "絮凝剂_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_库存量",
            dataid: "絮凝剂_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "次氯酸钠_液体_污水厂使用量",
            dataid: "次氯酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_一体化使用量",
            dataid: "次氯酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_到货量",
            dataid: "次氯酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量",
            dataid: "次氯酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量刻度",
            dataid: "次氯酸钠_液体_总使用量刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_库存量",
            dataid: "次氯酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "柠檬酸_固体_污水厂使用量",
            dataid: "柠檬酸_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_一体化使用量",
            dataid: "柠檬酸_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_到货量",
            dataid: "柠檬酸_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_总使用量",
            dataid: "柠檬酸_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_库存量",
            dataid: "柠檬酸_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "氢氧化钠_固体_污水厂使用量",
            dataid: "氢氧化钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_一体化使用量",
            dataid: "氢氧化钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_到货量",
            dataid: "氢氧化钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_总使用量",
            dataid: "氢氧化钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_库存量",
            dataid: "氢氧化钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_液体_污水厂使用量",
            dataid: "乙酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_一体化使用量",
            dataid: "乙酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_到货量",
            dataid: "乙酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用量",
            dataid: "乙酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用刻度",
            dataid: "乙酸钠_液体_总使用刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_库存量",
            dataid: "乙酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
    ],
    22: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_累计流量",
            dataid: "处理水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_处理水量",
            dataid: "处理水情况_处理水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_累计流量",
            dataid: "自来水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_用水量",
            dataid: "自来水情况_用水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_累计电量",
            dataid: "电量情况_累计电量",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_用电量",
            dataid: "电量情况_用电量",
            unit: "kw.h/60",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_好氧池",
            dataid: "SV30_好氧池",
            unit: "%",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_膜池",
            dataid: "SV30_膜池",
            unit: "%",
            type: 1,
            explain: "无",
        },


        {
            name: "PAC_固体_污水厂使用量",
            dataid: "PAC_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_一体化使用量",
            dataid: "PAC_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_到货量",
            dataid: "PAC_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_总使用量",
            dataid: "PAC_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_库存量",
            dataid: "PAC_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_醋酸钠_固体_污水厂使用量",
            dataid: "乙酸钠_醋酸钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_一体化使用量",
            dataid: "乙酸钠_醋酸钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_到货量",
            dataid: "乙酸钠_醋酸钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_总使用量",
            dataid: "乙酸钠_醋酸钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_库存量",
            dataid: "乙酸钠_醋酸钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "葡萄糖_固体_污水厂使用量",
            dataid: "葡萄糖_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_一体化使用量",
            dataid: "葡萄糖_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_到货量",
            dataid: "葡萄糖_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_总使用量",
            dataid: "葡萄糖_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_库存量",
            dataid: "葡萄糖_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "絮凝剂_固体_污水厂使用量",
            dataid: "絮凝剂_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_一体化使用量",
            dataid: "絮凝剂_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_到货量",
            dataid: "絮凝剂_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_总使用量",
            dataid: "絮凝剂_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_库存量",
            dataid: "絮凝剂_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "次氯酸钠_液体_污水厂使用量",
            dataid: "次氯酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_一体化使用量",
            dataid: "次氯酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_到货量",
            dataid: "次氯酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量",
            dataid: "次氯酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量刻度",
            dataid: "次氯酸钠_液体_总使用量刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_库存量",
            dataid: "次氯酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "柠檬酸_固体_污水厂使用量",
            dataid: "柠檬酸_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_一体化使用量",
            dataid: "柠檬酸_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_到货量",
            dataid: "柠檬酸_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_总使用量",
            dataid: "柠檬酸_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_库存量",
            dataid: "柠檬酸_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "氢氧化钠_固体_污水厂使用量",
            dataid: "氢氧化钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_一体化使用量",
            dataid: "氢氧化钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_到货量",
            dataid: "氢氧化钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_总使用量",
            dataid: "氢氧化钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_库存量",
            dataid: "氢氧化钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_液体_污水厂使用量",
            dataid: "乙酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_一体化使用量",
            dataid: "乙酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_到货量",
            dataid: "乙酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用量",
            dataid: "乙酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用刻度",
            dataid: "乙酸钠_液体_总使用刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_库存量",
            dataid: "乙酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
    ],
    21: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_累计流量",
            dataid: "处理水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_处理水量",
            dataid: "处理水情况_处理水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_累计流量",
            dataid: "自来水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_用水量",
            dataid: "自来水情况_用水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_累计电量",
            dataid: "电量情况_累计电量",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_用电量",
            dataid: "电量情况_用电量",
            unit: "kw.h/300",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_好氧池",
            dataid: "SV30_好氧池",
            unit: "%",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_膜池",
            dataid: "SV30_膜池",
            unit: "%",
            type: 1,
            explain: "无",
        },


        {
            name: "PAC_固体_污水厂使用量",
            dataid: "PAC_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_一体化使用量",
            dataid: "PAC_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_到货量",
            dataid: "PAC_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_总使用量",
            dataid: "PAC_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_库存量",
            dataid: "PAC_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_醋酸钠_固体_污水厂使用量",
            dataid: "乙酸钠_醋酸钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_一体化使用量",
            dataid: "乙酸钠_醋酸钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_到货量",
            dataid: "乙酸钠_醋酸钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_总使用量",
            dataid: "乙酸钠_醋酸钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_库存量",
            dataid: "乙酸钠_醋酸钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "葡萄糖_固体_污水厂使用量",
            dataid: "葡萄糖_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_一体化使用量",
            dataid: "葡萄糖_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_到货量",
            dataid: "葡萄糖_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_总使用量",
            dataid: "葡萄糖_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_库存量",
            dataid: "葡萄糖_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "絮凝剂_固体_污水厂使用量",
            dataid: "絮凝剂_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_一体化使用量",
            dataid: "絮凝剂_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_到货量",
            dataid: "絮凝剂_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_总使用量",
            dataid: "絮凝剂_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_库存量",
            dataid: "絮凝剂_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "次氯酸钠_液体_污水厂使用量",
            dataid: "次氯酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_一体化使用量",
            dataid: "次氯酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_到货量",
            dataid: "次氯酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量",
            dataid: "次氯酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量刻度",
            dataid: "次氯酸钠_液体_总使用量刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_库存量",
            dataid: "次氯酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "柠檬酸_固体_污水厂使用量",
            dataid: "柠檬酸_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_一体化使用量",
            dataid: "柠檬酸_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_到货量",
            dataid: "柠檬酸_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_总使用量",
            dataid: "柠檬酸_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_库存量",
            dataid: "柠檬酸_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "氢氧化钠_固体_污水厂使用量",
            dataid: "氢氧化钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_一体化使用量",
            dataid: "氢氧化钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_到货量",
            dataid: "氢氧化钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_总使用量",
            dataid: "氢氧化钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_库存量",
            dataid: "氢氧化钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_液体_污水厂使用量",
            dataid: "乙酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_一体化使用量",
            dataid: "乙酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_到货量",
            dataid: "乙酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用量",
            dataid: "乙酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用刻度",
            dataid: "乙酸钠_液体_总使用刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_库存量",
            dataid: "乙酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
    ],
    20: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_累计流量",
            dataid: "处理水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "处理水情况_处理水量",
            dataid: "处理水情况_处理水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_累计流量",
            dataid: "自来水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "自来水情况_用水量",
            dataid: "自来水情况_用水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_累计电量",
            dataid: "电量情况_累计电量",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量情况_用电量",
            dataid: "电量情况_用电量",
            unit: "kw.h/50",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_好氧池",
            dataid: "SV30_好氧池",
            unit: "%",
            type: 1,
            explain: "无",
        },
        {
            name: "SV30_膜池",
            dataid: "SV30_膜池",
            unit: "%",
            type: 1,
            explain: "无",
        },


        {
            name: "PAC_固体_污水厂使用量",
            dataid: "PAC_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_一体化使用量",
            dataid: "PAC_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_到货量",
            dataid: "PAC_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_总使用量",
            dataid: "PAC_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC_固体_库存量",
            dataid: "PAC_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_醋酸钠_固体_污水厂使用量",
            dataid: "乙酸钠_醋酸钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_一体化使用量",
            dataid: "乙酸钠_醋酸钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_到货量",
            dataid: "乙酸钠_醋酸钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_总使用量",
            dataid: "乙酸钠_醋酸钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_醋酸钠_固体_库存量",
            dataid: "乙酸钠_醋酸钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "葡萄糖_固体_污水厂使用量",
            dataid: "葡萄糖_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_一体化使用量",
            dataid: "葡萄糖_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_到货量",
            dataid: "葡萄糖_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_总使用量",
            dataid: "葡萄糖_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "葡萄糖_固体_库存量",
            dataid: "葡萄糖_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "絮凝剂_固体_污水厂使用量",
            dataid: "絮凝剂_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_一体化使用量",
            dataid: "絮凝剂_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_到货量",
            dataid: "絮凝剂_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_总使用量",
            dataid: "絮凝剂_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "絮凝剂_固体_库存量",
            dataid: "絮凝剂_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "次氯酸钠_液体_污水厂使用量",
            dataid: "次氯酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_一体化使用量",
            dataid: "次氯酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_到货量",
            dataid: "次氯酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量",
            dataid: "次氯酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_总使用量刻度",
            dataid: "次氯酸钠_液体_总使用量刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "次氯酸钠_液体_库存量",
            dataid: "次氯酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },



        {
            name: "柠檬酸_固体_污水厂使用量",
            dataid: "柠檬酸_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_一体化使用量",
            dataid: "柠檬酸_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_到货量",
            dataid: "柠檬酸_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_总使用量",
            dataid: "柠檬酸_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "柠檬酸_固体_库存量",
            dataid: "柠檬酸_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "氢氧化钠_固体_污水厂使用量",
            dataid: "氢氧化钠_固体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_一体化使用量",
            dataid: "氢氧化钠_固体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_到货量",
            dataid: "氢氧化钠_固体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_总使用量",
            dataid: "氢氧化钠_固体_总使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "氢氧化钠_固体_库存量",
            dataid: "氢氧化钠_固体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },


        {
            name: "乙酸钠_液体_污水厂使用量",
            dataid: "乙酸钠_液体_污水厂使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_一体化使用量",
            dataid: "乙酸钠_液体_一体化使用量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_到货量",
            dataid: "乙酸钠_液体_到货量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用量",
            dataid: "乙酸钠_液体_总使用量",
            unit: "cm(Kg)",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_总使用刻度",
            dataid: "乙酸钠_液体_总使用刻度",
            unit: "cm",
            type: 1,
            explain: "无",
        },
        {
            name: "乙酸钠_液体_库存量",
            dataid: "乙酸钠_液体_库存量",
            unit: "Kg",
            type: 1,
            explain: "无",
        },
    ],
    10: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_油污",
            dataid: "进水情况_油污",
            unit: "无 / 有",
            type: 2,
            explain: "无",
        },
        {
            name: "进水情况_浮渣",
            dataid: "进水情况_浮渣",
            unit: "少 / 多",
            type: 2,
            explain: "无",
        },

        {
            name: "出水情况_累计流量",
            dataid: "出水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_产水量",
            dataid: "出水情况_产水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_水质",
            dataid: "出水情况_水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "出水情况_计量",
            dataid: "出水情况_计量",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池运行情况_提升泵",
            dataid: "调节池运行情况_提升泵",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池运行情况_液位",
            dataid: "调节池运行情况_液位",
            unit: "m",
            type: 1,
            explain: "无",
        },

        {
            name: "厌氧池运行情况_搅拌",
            dataid: "厌氧池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "厌氧池运行情况_污泥色泽",
            dataid: "厌氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },

        {
            name: "缺氧池运行情况_搅拌",
            dataid: "缺氧池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "缺氧池运行情况_污泥色泽",
            dataid: "缺氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },

        {
            name: "好氧池运行情况_搅拌",
            dataid: "好氧池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池运行情况_污泥色泽",
            dataid: "好氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池运行情况_沉降比",
            dataid: "好氧池运行情况_沉降比",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "膜池运行情况_搅拌",
            dataid: "膜池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池运行情况_污泥色泽",
            dataid: "膜池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },
        {
            name: "膜池运行情况_沉降比",
            dataid: "膜池运行情况_沉降比",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "膜清洗",
            dataid: "膜清洗",
            unit: "已洗 / 未洗",
            type: 2,
            explain: "无",
        },

        {
            name: "回流泵运行情况",
            dataid: "回流泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "产水泵运行情况",
            dataid: "产水泵运行情况",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "产水泵运行情况_负压",
            dataid: "产水泵运行情况_负压",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },

        {
            name: "反洗泵运行情况",
            dataid: "反洗泵运行情况",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "风机运行情况_异响",
            dataid: "风机运行情况_异响",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_滴油",
            dataid: "风机运行情况_滴油",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_皮带",
            dataid: "风机运行情况_皮带",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_补油",
            dataid: "风机运行情况_补油",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_阀门启闭",
            dataid: "风机运行情况_阀门启闭",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_压力",
            dataid: "风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "风机运行情况_加油量",
            dataid: "风机运行情况_加油量",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "次钠投加系统",
            dataid: "次钠投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "次钠投加系统_到货量",
            dataid: "次钠投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次钠投加系统_加药量",
            dataid: "次钠投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "PAC投加系统",
            dataid: "PAC投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "PAC投加系统_到货量",
            dataid: "PAC投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC投加系统_加药量",
            dataid: "PAC投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "碳源投加系统",
            dataid: "碳源投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "碳源投加系统_到货量",
            dataid: "碳源投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "碳源投加系统_加药量",
            dataid: "碳源投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "紫外线消毒运行情况_报警器报警",
            dataid: "紫外线消毒运行情况_报警器报警",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },

        {
            name: "出水渠卫生情况",
            dataid: "出水渠卫生情况",
            unit: "干净 / 有泥沙 / 有青苔 / 有杂物",
            type: 2,
            explain: "无",
        },

        {
            name: "环境卫生",
            dataid: "环境卫生",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },

        {
            name: "环境安全",
            dataid: "环境安全",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },
    ],
    11: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_油污",
            dataid: "进水情况_油污",
            unit: "无 / 有",
            type: 2,
            explain: "无",
        },
        {
            name: "进水情况_浮渣",
            dataid: "进水情况_浮渣",
            unit: "少 / 多",
            type: 2,
            explain: "无",
        },

        {
            name: "出水情况_累计流量",
            dataid: "出水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_产水量",
            dataid: "出水情况_产水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_水质",
            dataid: "出水情况_水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池运行情况_提升泵",
            dataid: "调节池运行情况_提升泵",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池运行情况_液位",
            dataid: "调节池运行情况_液位",
            unit: "m",
            type: 1,
            explain: "无",
        },

        {
            name: "兼氧气池运行情况_搅拌",
            dataid: "兼氧气池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "兼氧池运行情况_污泥色泽",
            dataid: "兼氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },

        {
            name: "好氧池运行情况_搅拌",
            dataid: "好氧池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池运行情况_污泥色泽",
            dataid: "好氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },
        {
            name: "好氧池运行情况_沉降比",
            dataid: "好氧池运行情况_沉降比",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "混合搅拌区_絮凝效果",
            dataid: "混合搅拌区_絮凝效果",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "外回流泵运行情况",
            dataid: "外回流泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "风机运行情况_异响",
            dataid: "风机运行情况_异响",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_滴油",
            dataid: "风机运行情况_滴油",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_皮带",
            dataid: "风机运行情况_皮带",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_补油",
            dataid: "风机运行情况_补油",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_阀门启闭",
            dataid: "风机运行情况_阀门启闭",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "风机运行情况_压力",
            dataid: "风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "风机运行情况_加油量",
            dataid: "风机运行情况_加油量",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "PAC投加系统",
            dataid: "PAC投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "PAC投加系统_到货量",
            dataid: "PAC投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC投加系统_加药量",
            dataid: "PAC投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "出水渠卫生情况",
            dataid: "出水渠卫生情况",
            unit: "干净 / 有泥沙 / 有青苔 / 有杂物",
            type: 2,
            explain: "无",
        },

        {
            name: "环境卫生",
            dataid: "环境卫生",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },

        {
            name: "环境安全",
            dataid: "环境安全",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        }
    ],
    12: [
        {
            name: "格栅机_1",
            dataid: "格栅机_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "格栅机_2",
            dataid: "格栅机_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "栅渣输送机",
            dataid: "栅渣输送机",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "除臭系统",
            dataid: "除臭系统",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "粉碎格栅机_1",
            dataid: "粉碎格栅机_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "粉碎格栅机_2",
            dataid: "粉碎格栅机_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "粉碎格栅机_3",
            dataid: "粉碎格栅机_3",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "污水提升泵_1",
            dataid: "污水提升泵_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_2",
            dataid: "污水提升泵_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_3",
            dataid: "污水提升泵_3",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_4",
            dataid: "污水提升泵_4",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_5",
            dataid: "污水提升泵_5",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_6",
            dataid: "污水提升泵_6",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "灌体液位_1",
            dataid: "灌体液位_1",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "灌体液位_2",
            dataid: "灌体液位_2",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "灌体液位_3",
            dataid: "灌体液位_3",
            unit: "m",
            type: 1,
            explain: "无",
        },
    ],
    13: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_油污",
            dataid: "进水情况_油污",
            unit: "无 / 有",
            type: 2,
            explain: "无",
        },
        {
            name: "进水情况_浮渣",
            dataid: "进水情况_浮渣",
            unit: "少 / 多",
            type: 2,
            explain: "无",
        },

        {
            name: "出水情况_累计流量",
            dataid: "出水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_产水量",
            dataid: "出水情况_产水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_水质",
            dataid: "出水情况_水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "出水情况_计量",
            dataid: "出水情况_计量",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "格栅机运行情况",
            dataid: "格栅机运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池运行情况_提升泵",
            dataid: "调节池运行情况_提升泵",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池运行情况_液位",
            dataid: "调节池运行情况_液位",
            unit: "m",
            type: 1,
            explain: "无",
        },

        {
            name: "曝气池运行情况_曝气",
            dataid: "曝气池运行情况_曝气",
            unit: "均匀 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气池运行情况_污泥色泽",
            dataid: "曝气池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },
        {
            name: "曝气池运行情况_沉降比",
            dataid: "曝气池运行情况_沉降比",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "生化池风机运行情况_异响",
            dataid: "生化池风机运行情况_异响",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_滴油",
            dataid: "生化池风机运行情况_滴油",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_皮带",
            dataid: "生化池风机运行情况_皮带",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_补油",
            dataid: "生化池风机运行情况_补油",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_阀门启闭",
            dataid: "生化池风机运行情况_阀门启闭",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_压力",
            dataid: "生化池风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_加油量",
            dataid: "生化池风机运行情况_加油量",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "湿地植物生长情况",
            dataid: "湿地植物生长情况",
            unit: "良好 / 一般 / 差",
            type: 2,
            explain: "无",
        },

        {
            name: "紫外线消毒运行情况_报警器报警",
            dataid: "紫外线消毒运行情况_报警器报警",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },

        {
            name: "出水渠卫生情况",
            dataid: "出水渠卫生情况",
            unit: "干净 / 有泥沙 / 有青苔 / 有杂物",
            type: 2,
            explain: "无",
        },

        {
            name: "入河排水口",
            dataid: "入河排水口",
            unit: "清澈 / 无色 / 无味 / 其它",
            type: 2,
            explain: "无",
        },

        {
            name: "电量查询_电费余额",
            dataid: "电量查询_电费余额",
            unit: "",
            type: 1,
            explain: "无",
        },
        {
            name: "电量查询_总用电量",
            dataid: "电量查询_总用电量",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "环境卫生",
            dataid: "环境卫生",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },

        {
            name: "环境安全",
            dataid: "环境安全",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },
    ],
    14: [
        {
            name: "进水情况_累计流量",
            dataid: "进水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_进水量",
            dataid: "进水情况_进水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "进水情况_油污",
            dataid: "进水情况_油污",
            unit: "无 / 有",
            type: 2,
            explain: "无",
        },
        {
            name: "进水情况_浮渣",
            dataid: "进水情况_浮渣",
            unit: "少 / 多",
            type: 2,
            explain: "无",
        },

        {
            name: "出水情况_累计流量",
            dataid: "出水情况_累计流量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_产水量",
            dataid: "出水情况_产水量",
            unit: "m3",
            type: 1,
            explain: "无",
        },
        {
            name: "出水情况_水质",
            dataid: "出水情况_水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "出水情况_计量",
            dataid: "出水情况_计量",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "格栅机运行情况",
            dataid: "格栅机运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "调节池运行情况_提升泵",
            dataid: "调节池运行情况_提升泵",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "调节池运行情况_液位",
            dataid: "调节池运行情况_液位",
            unit: "m",
            type: 1,
            explain: "无",
        },

        {
            name: "兼氧气池运行情况_搅拌",
            dataid: "兼氧气池运行情况_搅拌",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "兼氧池运行情况_污泥色泽",
            dataid: "兼氧池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },

        {
            name: "MBBR池运行情况_曝气",
            dataid: "MBBR池运行情况_曝气",
            unit: "均匀 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "MBBR池运行情况_污泥色泽",
            dataid: "MBBR池运行情况_污泥色泽",
            unit: "黄褐色 / 黑色 / 无色",
            type: 2,
            explain: "无",
        },
        {
            name: "MBBR池运行情况_沉降比",
            dataid: "MBBR池运行情况_沉降比",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "混合搅拌区_絮凝效果",
            dataid: "混合搅拌区_絮凝效果",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "斜管沉淀池_出水水质",
            dataid: "斜管沉淀池_出水水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "砂滤池_出水水质",
            dataid: "砂滤池_出水水质",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },

        {
            name: "内回流泵运行情况",
            dataid: "内回流泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "外回流泵运行情况",
            dataid: "外回流泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "反洗泵运行情况",
            dataid: "反洗泵运行情况",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "生化池风机运行情况_异响",
            dataid: "生化池风机运行情况_异响",
            unit: "有 / 无",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_滴油",
            dataid: "生化池风机运行情况_滴油",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_皮带",
            dataid: "生化池风机运行情况_皮带",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_补油",
            dataid: "生化池风机运行情况_补油",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_阀门启闭",
            dataid: "生化池风机运行情况_阀门启闭",
            unit: "正常 / 异常",
            type: 2,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_压力",
            dataid: "生化池风机运行情况_压力",
            unit: "Mpa",
            type: 1,
            explain: "无",
        },
        {
            name: "生化池风机运行情况_加油量",
            dataid: "生化池风机运行情况_加油量",
            unit: "",
            type: 1,
            explain: "无",
        },

        {
            name: "PAC投加系统",
            dataid: "PAC投加系统",
            unit: "正常 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "PAC投加系统_到货量",
            dataid: "PAC投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "PAC投加系统_加药量",
            dataid: "PAC投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "次钠投加系统_到货量",
            dataid: "次钠投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "次钠投加系统_加药量",
            dataid: "次钠投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },

        {
            name: "碳源投加系统_到货量",
            dataid: "碳源投加系统_到货量",
            unit: "kg",
            type: 1,
            explain: "无",
        },
        {
            name: "碳源投加系统_加药量",
            dataid: "碳源投加系统_加药量",
            unit: "kg",
            type: 1,
            explain: "无",
        },


        {
            name: "出水渠卫生情况",
            dataid: "出水渠卫生情况",
            unit: "干净 / 有泥沙 / 有青苔 / 有杂物",
            type: 2,
            explain: "无",
        },

        {
            name: "环境卫生",
            dataid: "环境卫生",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },

        {
            name: "环境安全",
            dataid: "环境安全",
            unit: "合格 / 不合格",
            type: 2,
            explain: "无",
        },
    ],
    15: [
        {
            name: "格栅机_1",
            dataid: "格栅机_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "格栅机_2",
            dataid: "格栅机_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "栅渣输送机",
            dataid: "栅渣输送机",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "除臭系统",
            dataid: "除臭系统",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "粉碎格栅机_1",
            dataid: "粉碎格栅机_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "粉碎格栅机_2",
            dataid: "粉碎格栅机_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "粉碎格栅机_3",
            dataid: "粉碎格栅机_3",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "污水提升泵_1",
            dataid: "污水提升泵_1",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_2",
            dataid: "污水提升泵_2",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_3",
            dataid: "污水提升泵_3",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_4",
            dataid: "污水提升泵_4",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_5",
            dataid: "污水提升泵_5",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },
        {
            name: "污水提升泵_6",
            dataid: "污水提升泵_6",
            unit: "正常运转 / 停机 / 故障",
            type: 2,
            explain: "无",
        },

        {
            name: "灌体液位_1",
            dataid: "灌体液位_1",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "灌体液位_2",
            dataid: "灌体液位_2",
            unit: "m",
            type: 1,
            explain: "无",
        },
        {
            name: "灌体液位_3",
            dataid: "灌体液位_3",
            unit: "m",
            type: 1,
            explain: "无",
        },
    ],
    16: [
        {
            name: "厂站卫生是否清理",
            dataid: "厂站卫生是否清理",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },

        {
            name: "整体设备运行是否正常",
            dataid: "整体设备运行是否正常",
            unit: "是 / 否",
            type: 2,
            explain: "无",
        },

        {
            name: "格栅积水井垃圾清理情况",
            dataid: "格栅积水井垃圾清理情况",
            unit: "已清理 / 未清理",
            type: 2,
            explain: "无",
        },

    ],
    17: [
        {
            name: "耗电量",
            dataid: "耗电量",
            unit: "kwh",
            type: 1,
            explain: "无",
        },
    ]
}